import { db, auth } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  arrayUnion,
  updateDoc,
  doc,
} from "firebase/firestore";

export async function findUserByEmail(email: string) {
  try {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    }
    return null;
  } catch (error) {
    console.error("Error finding user:", error);
    return null;
  }
}

export async function createPendingInvite(
  documentId: string,
  email: string,
  role: string,
  inviteToken: string
) {
  try {
    const invitesRef = collection(db, "pending_invites");
    const invite = {
      email,
      documentId,
      role,
      inviteToken,
      createdAt: new Date().toISOString(),
      status: "pending",
    };

    await addDoc(invitesRef, invite);
    return true;
  } catch (error) {
    console.error("Error creating invite:", error);
    return false;
  }
}
export async function getInviteDetails(inviteToken: string) {
  try {
    const invitesRef = collection(db, "pending_invites");
    const inviteQuery = query(
      invitesRef,
      where("inviteToken", "==", inviteToken)
    );
    const querySnapshot = await getDocs(inviteQuery);

    if (querySnapshot.empty) {
      throw new Error("No invitation found for this token");
    }

    // Assuming the token is unique, take the first document
    const inviteDoc = querySnapshot.docs[0];
    return {
      id: inviteDoc.id,
      ...inviteDoc.data(),
    };
  } catch (error) {
    console.error("Error retrieving invite details:", error);
    throw error; // Propagate the error to handle it in the caller
  }
}

export async function setInviteToUsed(inviteToken: string, userUid: string) {
  try {
    const invitesRef = collection(db, "pending_invites");
    const inviteQuery = query(
      invitesRef,
      where("inviteToken", "==", inviteToken)
    );
    const querySnapshot = await getDocs(inviteQuery);

    if (querySnapshot.empty) {
      throw new Error("No invitation found for this token");
    }

    // Assuming the token is unique, take the first document
    const inviteDoc = querySnapshot.docs[0];
    const inviteData = inviteDoc.data();

    const { documentId, email, role } = inviteData;

    // Mark the invitation as "complete"
    await updateDoc(doc(db, "pending_invites", inviteDoc.id), {
      status: "complete",
    });

    // Update the designs document with the new collaborator
    const docRef = doc(db, "designs", documentId);
    await updateDoc(docRef, {
      collaborators: arrayUnion({
        email,
        role,
        uid: userUid,
      }),
      collaboratorIds: arrayUnion({
        uid: userUid,
      }),
    });

    return true;
  } catch (error) {
    console.error("Error processing invite and updating document:", error);
    throw error;
  }
}
