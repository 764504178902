/**
 * @file Engine.ts
 * @module Engine
 * @description The main engine of the drill 2D graphics engine. This is responsible for rendering the drill graphics.
 * The engine itself uses the paper.js library to handle rendering, but also to create a context that is agnostic to the
 * underlying canvas size. This allows us to manipulate the drill separately from the rendering context, and keep drill
 * sizing consistent across different screen sizes.
 * @author Marcus Dubreuil
 */
import { PaperScope, Color, Group, Layer, Point, Size, Rectangle, Path, PointText, } from "paper";
import { HashType } from "../Drill";
import Performer from "../Performer";
// Regardless of the current canvas size, the width and height of a page of
// drill is always static.
// 8 cells (steps) make up 5 yards.
export const CELL_SIZE = 8;
export const YARD_SIZE = (8 * 8) / 5;
export const HIGHSCHOOL_HASH_LOCATION = 17.7777777777777 * YARD_SIZE; // High school hashes are 53 feet 4 inches from the sideline, or 17.7777777777777 yards
export const COLLEGE_HASH_LOCATION = 20 * YARD_SIZE; // College hashes are 60 feet from the sideline, or 20 yards
export const PROFESSIONAL_HASH_LOCATION = 23.5833333333333 * YARD_SIZE; // Professional hashes are 70 feet 9 inches from each sideline, or 23.5833333333333 yards
export const HASH_SIZE = CELL_SIZE * 2; // Realistic sizing would usually be 24 inches, so (0.666667 * YARD_SIZE), but this is hard to see, so we make it 2 cells
const HALF_YARD_LINE_THICKNESS = 1;
const YARD_LINE_THICKNESS = 1;
export const DEFAULT_DRILL_COLORS = {
    background: new Color(0.8, 0.8, 0.8, 1),
    cells: new Color("#0094C6"),
    halfYardLines: new Color("#005E7C"),
    yardLines: new Color("#001242"),
    hashes: new Color("#000022"),
    performers: new Color("#040F16"),
    yardLineLetters: new Color(0, 0.4),
    selectedPerformer: new Color("#FF0000"),
    selectionRectangle: new Color("#FF0000"),
    previousPositionLine: new Color("#FF0000AA"),
    nextPositionLine: new Color("#028F00AA"),
    collision: new Color("#FF0000"),
    performerPaintPath: new Color("#000000"),
};
DEFAULT_DRILL_COLORS.cells.alpha = 0.25;
DEFAULT_DRILL_COLORS.halfYardLines.alpha = 0.25;
DEFAULT_DRILL_COLORS.yardLines.alpha = 0.5;
export var EditMode;
(function (EditMode) {
    EditMode[EditMode["PAN"] = 0] = "PAN";
    EditMode[EditMode["SELECT_AND_MOVE"] = 1] = "SELECT_AND_MOVE";
    EditMode[EditMode["PAINT_PERFORMERS"] = 2] = "PAINT_PERFORMERS";
})(EditMode || (EditMode = {}));
export var MovementSensitivity;
(function (MovementSensitivity) {
    MovementSensitivity[MovementSensitivity["FULL_STEP"] = 0] = "FULL_STEP";
    MovementSensitivity[MovementSensitivity["HALF_STEP"] = 1] = "HALF_STEP";
    MovementSensitivity[MovementSensitivity["FREE"] = 2] = "FREE";
})(MovementSensitivity || (MovementSensitivity = {}));
export default class Engine {
    canvas;
    ctx;
    drillPaper;
    canvasPaper;
    layers;
    colors;
    defaultCanvasRectangle;
    _endZones;
    drillWidth = 0;
    drillHeight = 0;
    // A football field is usually technically 53.3333333 yards wide
    fieldYardHeight = 50;
    _hashType;
    _editMode = EditMode.PAN;
    currentDrawBounds;
    // When moving performers, this is the sensitivity of the movement.
    movementSensitivity = MovementSensitivity.FULL_STEP;
    // This contains all selected performers
    _selectedPerformers = [];
    // This contains the point that was clicked
    // as the beginning of a drag event.
    _movementOriginPoint = null;
    _movementOriginStepPosition = null;
    _movementArrows = true;
    _pathCollisionDetection = true;
    startSelectionPoint = null;
    endSelectionPoint = null;
    selectionRectangle = null;
    performerPaintPath = null;
    newPerformerSymbol = "X";
    performerPaintStepSeperation = 1;
    lastDrawnDrill = null;
    lastDrawnPerformersElements = [];
    _pageTransitionPercentage = 0;
    _eventListeners = {
        "performers-selected": [],
    };
    constructor(canvas, colors = DEFAULT_DRILL_COLORS, endZones = false, hashType = HashType.HIGH_SCHOOL, fieldYardHeight = 50) {
        this.canvas = canvas;
        this.colors = colors;
        this.ctx = this.canvas.getContext("2d");
        // Handle Device Pixel Ratio
        const dpr = window.devicePixelRatio || 1;
        // Scale the canvas context to account for the device pixel ratio
        this.ctx.scale(1 / dpr, 1 / dpr);
        this._endZones = endZones;
        this._hashType = hashType;
        this.fieldYardHeight = fieldYardHeight;
        this._calculateDrillDimensions();
        this.canvasPaper = new PaperScope();
        this.canvasPaper.setup(this.canvas);
        this.defaultCanvasRectangle = new Rectangle(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
        this.currentDrawBounds = this.defaultCanvasRectangle.clone();
        this.drillPaper = new PaperScope();
        this.drillPaper.setup(new Size(this.drillWidth, this.drillHeight));
        this.layers = {
            mesh: new Layer(),
            yardLines: new Layer(),
            drill: new Layer(),
            overlays: new Layer(),
        };
        // Generate layers
        this._createMeshLayer();
        this._createYardLinesLayer();
        this._createOverlayLayer();
    }
    get pageTransitionPercentage() {
        return this._pageTransitionPercentage;
    }
    set pageTransitionPercentage(value) {
        value /= 100;
        if (value < 0) {
            value = 0;
        }
        if (value > 1) {
            value = 1;
        }
        this._pageTransitionPercentage = value;
        this._updatePerformerPositionsBasedOnTransitionPercentage();
    }
    get editMode() {
        return this._editMode;
    }
    set editMode(value) {
        this._editMode = value;
    }
    get endZones() {
        return this._endZones;
    }
    get rawDrillPaperScope() {
        return this.drillPaper;
    }
    get rawCanvasPaperScope() {
        return this.canvasPaper;
    }
    /**
     * Toggles the visibility of the end zones.
     * @param value - A boolean indicating whether to show the end zones.
     */
    set endZones(value) {
        this._endZones = value;
        this._calculateDrillDimensions();
        this._createMeshLayer();
        this._createYardLinesLayer();
    }
    get hashType() {
        return this._hashType;
    }
    /**
     * Sets the hash type for the drill.
     * @param value - The hash type to set.
     */
    set hashType(value) {
        this._hashType = value;
        this._createYardLinesLayer();
    }
    get movementArrows() {
        return this._movementArrows;
    }
    set movementArrows(value) {
        this._movementArrows = value;
        if (this.lastDrawnDrill) {
            this.drawDrill(this.lastDrawnDrill);
        }
    }
    get collisionDetection() {
        return this._pathCollisionDetection;
    }
    set collisionDetection(value) {
        this._pathCollisionDetection = value;
        this._createOverlayLayer();
    }
    setFieldYardHeight(value) {
        this.fieldYardHeight = value;
        this._calculateDrillDimensions();
        this._createYardLinesLayer();
    }
    set movementOriginPoint(value) {
        this._movementOriginPoint = value;
        if (value) {
            this._movementOriginStepPosition = this._roundPosition(this.getStepPositionAtPoint(value.x, value.y, true));
        }
        else {
            this._movementOriginStepPosition = null;
        }
    }
    get movementOriginPoint() {
        return this._movementOriginPoint;
    }
    /**
     * Adds a listener for a specific event.
     * performers-selected: Triggered when performers are selected or deselected, calls the callback with the performers that are selected.
     *
     * @param event A string representing the event to listen for
     * @param callback A function to call when the event is triggered
     */
    on(event, callback) {
        this._eventListeners[event].push(callback);
    }
    /**
     * This function removes a listener from a specific event. Should be called to clear a specific listener.
     * @param event The event to remove the listener from
     * @param callback The function to remove from the event
     */
    removeOn(event, callback) {
        this._eventListeners[event] = this._eventListeners[event].filter((item) => item !== callback);
    }
    /**
     * Clears all listeners, or all listeners of a specific type of event.
     */
    clearListeners(type) {
        if (type) {
            this._eventListeners[type] = [];
        }
        else {
            this._eventListeners = {
                "performers-selected": [],
            };
        }
    }
    /**
     * Calculates the dimensions of the drill based on the standard football field size and
     * whether end zones are included.
     */
    _calculateDrillDimensions() {
        const yardLines = this._endZones ? 120 : 100;
        // 64 pixels for every 5 yards.
        this.drillWidth = (yardLines / 5) * CELL_SIZE * 8;
        // The height of a standard football pitch is 53.3 yards. We'll still use the cell size to calculate the height.
        this.drillHeight = (this.fieldYardHeight / 5) * CELL_SIZE * 8;
    }
    /**
     * This creates the mesh layer, which is the mesh that lives behind
     * the dots, containing only the 1x1 cells.
     */
    _createMeshLayer() {
        this.drillPaper.activate();
        this.layers.mesh.removeChildren();
        this.layers.mesh.activate();
        let startX = this._endZones ? CELL_SIZE * 16 : 0;
        let endX = this._endZones
            ? this.drillWidth - CELL_SIZE * 16
            : this.drillWidth;
        // Create vertical lines across the full drill width
        for (let x = startX; x < endX; x += CELL_SIZE) {
            const line = new Path.Line(new Point(x, 0), new Point(x, this.drillHeight));
            line.strokeColor = this.colors.cells;
        }
        // Create horizontal lines across the full drill height
        for (let y = 0; y < this.drillHeight; y += CELL_SIZE) {
            const line = new Path.Line(new Point(startX, y), new Point(endX, y));
            line.strokeColor = this.colors.cells;
        }
        // Draw one last line at the bottom of the drill
        const bottomLine = new Path.Line(new Point(0, this.drillHeight), new Point(this.drillWidth, this.drillHeight));
        bottomLine.strokeColor = this.colors.cells;
    }
    _createOverlayLayer() {
        this.drillPaper.activate();
        this.layers.overlays.removeChildren();
        this.layers.overlays.activate();
        // Draw the performer paint path
        if (this.performerPaintPath) {
            this.layers.overlays.addChild(this.performerPaintPath);
            // Debug, to show the points of the path along step size
            // for (let i = 0; i < this.performerPaintPath.length; i += CELL_SIZE) {
            //   const point = this.performerPaintPath.getPointAt(i);
            //   const dot = new Path.Circle(point, 2);
            //   dot.fillColor = this.colors.selectedPerformer;
            //   this.layers.overlays.addChild(dot);
            // }
        }
        // Draw the selection rectangle
        if (this.selectionRectangle) {
            this.selectionRectangle.strokeColor = this.colors.selectionRectangle;
            this.selectionRectangle.strokeWidth = 2;
            this.layers.overlays.addChild(this.selectionRectangle);
        }
        // Get each next position arrow for each performer
        const nextPositionArrows = this.layers.drill.children.filter((item) => item.data.isNextPositionArrow && item.className === "Group");
        // Check if any arrows intersect with each other
        if (this.collisionDetection) {
            for (let i = 0; i < nextPositionArrows.length; i++) {
                for (let j = 0; j < nextPositionArrows.length; j++) {
                    if (i === j) {
                        continue;
                    }
                    const arrow1 = nextPositionArrows[i].children.find((item) => item.data.isArrowLine);
                    const arrow2 = nextPositionArrows[j].children.find((item) => item.data.isArrowLine);
                    if (!arrow1 || !arrow2) {
                        continue;
                    }
                    const intersections = arrow1.getIntersections(arrow2);
                    // Create an X where the arrows intersect
                    for (const intersection of intersections) {
                        const x = new Path.Line(intersection.point.add(new Point(-5, -5)), intersection.point.add(new Point(5, 5)));
                        x.strokeColor = this.colors.selectedPerformer;
                        x.strokeWidth = 2;
                        this.layers.overlays.addChild(x);
                        const x2 = new Path.Line(intersection.point.add(new Point(5, -5)), intersection.point.add(new Point(-5, 5)));
                        x2.strokeColor = this.colors.selectedPerformer;
                        x2.strokeWidth = 2;
                        this.layers.overlays.addChild(x2);
                    }
                }
            }
        }
    }
    /**
     * You probably don't need to call this function directly. It is called by the Drill class
     * whenever interaction methods are called. However, if you need to get the position of a
     * point within the drill based on a click within the canvas, you can use this function.
     * @param x Position of the click on the canvas
     * @param y Position of the click on the canvas
     * @returns {paper.Point} The position of the click within the drill
     */
    getDrillPosition(x, y, w, h) {
        const bounds = this.currentDrawBounds;
        // Calculate the position of the click within the bounds of the drill
        const boundsPoint = new Point(x - bounds.x, y - bounds.y);
        // Scale the point to the drill size
        boundsPoint.x = (boundsPoint.x / bounds.width) * this.drillWidth;
        boundsPoint.y = (boundsPoint.y / bounds.height) * this.drillHeight;
        if (w && h) {
            const boundsWidth = (w / bounds.width) * this.drillWidth;
            const boundsHeight = (h / bounds.height) * this.drillHeight;
            return new Rectangle(boundsPoint, new Size(boundsWidth, boundsHeight));
        }
        return boundsPoint;
    }
    /**
     * Get the drill coordinates from a step position.
     * @param position StepPosition to convert to a drill position
     */
    getDrillCoordinatesFromStepPosition(position) {
        const centerX = this.drillWidth / 2;
        const centerY = this.drillHeight / 2;
        return new Point(position.horizontalStepsFrom50 * CELL_SIZE + centerX, centerY - position.verticalStepsFromCenter * CELL_SIZE);
    }
    /**
     * Handle a click event on the canvas. Specifically, a single click. Not
     * a mouse drag event or a mouse down event.
     *
     * This should tie only to a mouse release event that is not a drag event.
     *
     * In select and move mode, this will select a performer if the click is on a performer symbol.
     * Otherwise, it will deselect all performers.
     *
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     */
    handleClick(x, y) {
        const point = this.getDrillPosition(x, y);
        const hadSelectedPerformers = this._selectedPerformers.length > 0;
        // First, deselect all performers
        this._selectedPerformers = [];
        this.selectionRectangle = null;
        this._createOverlayLayer();
        // Hit test the drill layer to find the performer
        if (this._editMode === EditMode.SELECT_AND_MOVE ||
            this._editMode === EditMode.PAN) {
            const hit = this.layers.drill.hitTest(point);
            if (hit && hit.item.data.performer && hit.item.data.isSymbol) {
                this._selectedPerformers.push(hit.item.data.performer);
                this._updateSelectionRectangle();
            }
        }
        if (this._selectedPerformers.length > 0 || hadSelectedPerformers) {
            this._eventListeners["performers-selected"].forEach((callback) => {
                callback(this._selectedPerformers);
            });
        }
    }
    _updateSelectionRectangle() {
        const performerItems = this.layers.drill.children.filter((item) => this._selectedPerformers.includes(item.data.performer) &&
            !item.data.isNextPositionArrow);
        const bounds = performerItems.reduce((bounds, item) => {
            return bounds ? bounds.unite(item.bounds) : item.bounds;
        }, null);
        if (bounds) {
            this.selectionRectangle = new Path.Rectangle(bounds);
        }
        this._createOverlayLayer();
    }
    /**
     * Gets the step position given a point on the canvas, or
     * the drill point if the third parameter is set to true.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     * @param isDrillPoint If true, the point is a drill point, not a canvas point
     */
    getStepPositionAtPoint(x, y, isDrillPoint = false) {
        const point = isDrillPoint ? new Point(x, y) : this.getDrillPosition(x, y);
        const centerX = this.drillWidth / 2;
        const centerY = this.drillHeight / 2;
        const horizontalStepsFrom50 = (point.x - centerX) / CELL_SIZE;
        const verticalStepsFromCenter = (centerY - point.y) / CELL_SIZE;
        return {
            horizontalStepsFrom50,
            verticalStepsFromCenter,
        };
    }
    /**
     * Checks if a provided point is within the selection rectangle
     * This can be used at the beginning of a drag event to determine if the
     * drag event is moving performers or if it is meant to pan the drill.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     * @returns The starting point of the drag event if the point is within the selection rectangle
     */
    isPointInSelectionRectangle(x, y) {
        const point = this.getDrillPosition(x, y);
        if (this._editMode !== EditMode.SELECT_AND_MOVE ||
            !this._selectedPerformers ||
            this._selectedPerformers.length === 0) {
            return null;
        }
        // Check if the point is within the selection rectangle
        if (this.selectionRectangle?.contains(point)) {
            return point;
        }
        return null;
    }
    /**
     * Rounds the position based on the movement sensitivity.
     */
    _roundPosition(position) {
        switch (this.movementSensitivity) {
            case MovementSensitivity.FULL_STEP:
                return {
                    horizontalStepsFrom50: Math.round(position.horizontalStepsFrom50),
                    verticalStepsFromCenter: Math.round(position.verticalStepsFromCenter),
                };
            case MovementSensitivity.HALF_STEP:
                return {
                    horizontalStepsFrom50: Math.round(position.horizontalStepsFrom50 * 2) / 2,
                    verticalStepsFromCenter: Math.round(position.verticalStepsFromCenter * 2) / 2,
                };
            case MovementSensitivity.FREE:
                return position;
        }
    }
    /**
     * Moves the selected performers to the provided point.
     * This moves the performers based on the performer that the
     * drag event started on. This is set by the clickedSelectedPerformer.
     *
     * @param x The canvas x position to move the performers to
     * @param x The canvas y position to move the performers to
     */
    moveSelectedPerformers(x, y) {
        if (!this._movementOriginStepPosition) {
            return;
        }
        const updatedPosition = this._roundPosition(this.getStepPositionAtPoint(x, y));
        // Record the change in position
        const deltaHorizontalSteps = updatedPosition.horizontalStepsFrom50 -
            this._movementOriginStepPosition.horizontalStepsFrom50;
        const deltaVerticalSteps = updatedPosition.verticalStepsFromCenter -
            this._movementOriginStepPosition.verticalStepsFromCenter;
        if (this.lastDrawnDrill) {
            this.lastDrawnDrill.disableDraw = true;
        }
        let changed = false;
        // Update the positions of all selected performers based on the change
        for (const performer of this._selectedPerformers) {
            const position = performer.drill.selectedPage.getPerformerPosition(performer);
            if (!position) {
                continue;
            }
            const newPosition = this._roundPosition({
                horizontalStepsFrom50: position.horizontalStepsFrom50 + deltaHorizontalSteps,
                verticalStepsFromCenter: position.verticalStepsFromCenter + deltaVerticalSteps,
            });
            performer.drill.selectedPage.setPerformerPosition(performer, newPosition);
            if (position.horizontalStepsFrom50 !== newPosition.horizontalStepsFrom50 ||
                position.verticalStepsFromCenter !== newPosition.verticalStepsFromCenter) {
                changed = true;
            }
        }
        if (this.lastDrawnDrill) {
            this.lastDrawnDrill.disableDraw = false;
            if (changed)
                this.lastDrawnDrill.draw();
        }
        // Update the movement origin point
        this._movementOriginPoint = this.getDrillPosition(x, y);
        this._movementOriginStepPosition = updatedPosition;
        this._updateSelectionRectangle();
    }
    /**
     * Starts a selection event. This should be called when the user begins a selection
     * while in select and move mode.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     */
    startSelection(x, y) {
        this.startSelectionPoint = this.getDrillPosition(x, y);
    }
    /**
     * This function will update the selection rectangle based on the current mouse position
     * within the canvas. This should be called when the user is dragging the mouse to select,
     * after the startSelection function has been called.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     */
    updateSelection(x, y) {
        this.endSelectionPoint = this.getDrillPosition(x, y);
        if (this.startSelectionPoint) {
            this.selectionRectangle = new Path.Rectangle(this.startSelectionPoint, this.endSelectionPoint);
            this._createOverlayLayer();
        }
    }
    /**
     * This function will end the selection event. This should be called when the user releases
     * the mouse button after starting a selection event. This will select all performers within
     * the selection rectangle.
     */
    endSelection() {
        if (!this.selectionRectangle) {
            return;
        }
        // Get all performers within the selection rectangle
        const selectedPerformerItems = this.layers.drill.children.filter((item) => {
            if (!item.data.performer) {
                return false;
            }
            return this.selectionRectangle?.contains(item.position);
        });
        const hadSelectedPerformers = this._selectedPerformers.length > 0;
        // Get unique performers from the items
        this._selectedPerformers = Array.from(new Set(selectedPerformerItems.map((item) => item.data.performer)));
        if (this._selectedPerformers.length > 0 || hadSelectedPerformers) {
            this._eventListeners["performers-selected"].forEach((callback) => {
                callback(this._selectedPerformers);
            });
        }
        // Clean up the selection rectangle
        this.selectionRectangle.remove();
        this.selectionRectangle = null;
        this.startSelectionPoint = null;
        this.endSelectionPoint = null;
        // Re-create the selection rectangle to encompass the selected performers
        if (selectedPerformerItems.length > 0) {
            this._updateSelectionRectangle();
        }
    }
    /**
     * Starts a performer paint event. This should be called when the user begins painting
     * while in paint performers mode.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     */
    startPerformerPainting(x, y) {
        this.performerPaintPath = new Path();
        this.performerPaintPath.strokeColor = this.colors.performerPaintPath;
        this.performerPaintPath.strokeWidth = 5;
        this.performerPaintPath.add(this.getDrillPosition(x, y));
        this._createOverlayLayer();
    }
    /**
     * This function will update the performer paint path based on the current mouse position
     * within the canvas. This should be called when the user is dragging the mouse to paint,
     * after the startPerformerPainting function has been called.
     * @param x The canvas x position of the click
     * @param y The canvas y position of the click
     */
    updatePerformerPainting(x, y) {
        if (!this.performerPaintPath) {
            return;
        }
        this.performerPaintPath.add(this.getDrillPosition(x, y));
        this._createOverlayLayer();
    }
    /**
     * This function will end the performer paint event. This should be called when the user releases
     * the mouse button after starting a paint event. This will normalize the path to be the size
     * of the number of provided steps, and then create a new performer along the path with the
     * provided symbol
     * @param drill The drill to add the performer to, and it will use the selected page
     */
    endPerformerPainting(drill) {
        if (!this.performerPaintPath) {
            return;
        }
        const symbol = this.newPerformerSymbol;
        const separation = this.performerPaintStepSeperation * CELL_SIZE;
        const pathLength = this.performerPaintPath.length;
        // Get the last performer number with the same symbol from the drill
        let lastPerformerNumber = drill.performers.reduce((max, performer) => {
            if (performer.symbol === symbol) {
                return performer.number > max ? performer.number : max;
            }
            return max;
        }, 0);
        drill.disableDraw = true;
        for (let i = 0; i < pathLength; i += separation) {
            const point = this.performerPaintPath.getPointAt(i);
            const position = this.getStepPositionAtPoint(point.x, point.y, true);
            const performerNumber = lastPerformerNumber + 1;
            const perfomer = new Performer(drill, `${symbol}${performerNumber}`, performerNumber, symbol);
            drill.addPerformer(perfomer, undefined, position);
            lastPerformerNumber = performerNumber;
        }
        this.performerPaintPath.remove();
        this.performerPaintPath = null;
        drill.disableDraw = false;
        drill.draw();
        this._createOverlayLayer();
    }
    /**
     * This function will create the yard lines layer. which contains both the lines
     * themselves as well as the numbers that represent the yardage, and hashes.
     */
    _createYardLinesLayer() {
        this.drillPaper.activate();
        this.layers.yardLines.removeChildren();
        // Yard lines are created every 8 cells, but we also create a line every 4th cell
        // to account for the midpoints.
        for (let x = 0; x <= this.drillWidth; x += CELL_SIZE * 8) {
            this.layers.yardLines.activate();
            const line = new Path.Line(new Point(x, 0), new Point(x, this.drillHeight));
            line.strokeColor = this.colors.yardLines;
            line.strokeWidth = YARD_LINE_THICKNESS;
            // Add hash marks
            const hashY = this._hashType === HashType.HIGH_SCHOOL
                ? HIGHSCHOOL_HASH_LOCATION
                : this._hashType === HashType.COLLEGE
                    ? COLLEGE_HASH_LOCATION
                    : PROFESSIONAL_HASH_LOCATION;
            const hashTop = new Path.Line(new Point(x - HASH_SIZE / 2, hashY), new Point(x + HASH_SIZE / 2, hashY));
            hashTop.strokeColor = this.colors.hashes;
            const hashBottom = new Path.Line(new Point(x - HASH_SIZE / 2, this.drillHeight - hashY), new Point(x + HASH_SIZE / 2, this.drillHeight - hashY));
            hashBottom.strokeColor = this.colors.hashes;
            // Add yardage numbers
            // One point will be 9 yards from the top of the field
            const topYardMarkingLocation = new Point(x, YARD_SIZE * 9);
            const bottomYardMarkingLocation = new Point(x, this.drillHeight - YARD_SIZE * 9);
            const adjustedX = this._endZones ? x - CELL_SIZE * 16 : x;
            const numberOfYardsFromLeft = (adjustedX / (CELL_SIZE * 8)) * 5;
            const yardNumber = numberOfYardsFromLeft <= 50
                ? numberOfYardsFromLeft
                : 100 - numberOfYardsFromLeft;
            // Top text
            const text = new PointText(topYardMarkingLocation);
            text.content = yardNumber.toString();
            text.fillColor = this.colors.yardLineLetters;
            text.strokeColor = this.colors.yardLineLetters;
            text.strokeWidth = 3;
            text.fontSize = 30;
            text.justification = "center";
            // Bottom text
            const bottomText = text.clone();
            bottomText.point = bottomYardMarkingLocation;
        }
        for (let x = 0; x <= this.drillWidth; x += CELL_SIZE * 4) {
            const line = new Path.Line(new Point(x, 0), new Point(x, this.drillHeight));
            line.strokeColor = this.colors.halfYardLines;
            line.strokeWidth = HALF_YARD_LINE_THICKNESS;
            this.layers.yardLines.addChild(line);
        }
        // Draw horizontal lines every 4 cells
        for (let y = 0; y <= this.drillHeight; y += CELL_SIZE * 4) {
            const line = new Path.Line(new Point(0, y), new Point(this.drillWidth, y));
            line.strokeColor = this.colors.halfYardLines;
            line.strokeWidth = HALF_YARD_LINE_THICKNESS;
            this.layers.yardLines.addChild(line);
        }
    }
    /**
     * This function will update the positions of the performers based on the
     * transition percentage. This is used to animate the transition between
     * pages.
     */
    _updatePerformerPositionsBasedOnTransitionPercentage() {
        const drill = this.lastDrawnDrill;
        if (!drill ||
            this.lastDrawnPerformersElements.length === 0 ||
            !drill.selectedPage ||
            drill.pages.length < drill.selectedPageIndex + 2) {
            return;
        }
        const currentPage = drill.selectedPage;
        const nextPage = drill.pages[drill.selectedPageIndex + 1];
        for (const performerElements of this.lastDrawnPerformersElements) {
            const performer = performerElements.performer;
            const currentPagePosition = currentPage.getPerformerPosition(performer);
            const nextPagePosition = nextPage.getPerformerPosition(performer);
            if (!currentPagePosition || !nextPagePosition) {
                continue;
            }
            const currentPagePoint = this.getDrillCoordinatesFromStepPosition(currentPagePosition);
            const nextPagePoint = this.getDrillCoordinatesFromStepPosition(nextPagePosition);
            const transitionPoint = currentPagePoint.add(nextPagePoint
                .subtract(currentPagePoint)
                .multiply(this.pageTransitionPercentage));
            // Use the dot as a guide for the position update of other elements.
            let lastPerformerDotPosition = performerElements.dot.position;
            performerElements.dot.position = transitionPoint;
            // Make sure to set the center of the symbol to the transition point
            // Update other elements based on their position difference
            const symbol = performerElements.symbol;
            const text = performerElements.text.text;
            const textLine = performerElements.text.line;
            symbol.position = symbol.position
                .subtract(lastPerformerDotPosition)
                .add(transitionPoint);
            text.position = text.position
                .subtract(lastPerformerDotPosition)
                .add(transitionPoint);
            textLine.position = textLine.position
                .subtract(lastPerformerDotPosition)
                .add(transitionPoint);
        }
    }
    /**
     * This function is called by the Drill class to draw the drill to the canvas.
     * It does not need to be called directly by the user.
     */
    drawDrill(drill) {
        this.lastDrawnDrill = drill;
        this.lastDrawnPerformersElements = [];
        this.drillPaper.activate();
        this.layers.drill.removeChildren();
        const performerPositions = drill.selectedPage.performerPositions;
        // If movement arrows are enabled, draw them before the performers so that
        // they are below the performers.
        if (this._movementArrows) {
            for (const [performer, position] of performerPositions) {
                const currentPoint = this.getDrillCoordinatesFromStepPosition(position);
                // Check if there is a page before this one
                if (drill.selectedPageIndex > 0) {
                    const previousPage = drill.pages[drill.selectedPageIndex - 1];
                    const previousPosition = previousPage.getPerformerPosition(performer);
                    if (previousPosition) {
                        const previousPoint = this.getDrillCoordinatesFromStepPosition(previousPosition);
                        const line = new Path.Line(previousPoint, currentPoint);
                        line.strokeColor = this.colors.previousPositionLine;
                        line.strokeWidth = 2;
                        this.layers.drill.addChild(line);
                        // Draw a little red dot at the previous position
                        const dot = new Path.Circle(previousPoint, 2.5);
                        dot.fillColor = this.colors.previousPositionLine;
                        this.layers.drill.addChild(dot);
                    }
                }
                // Check if there is a page after this one
                if (drill.selectedPageIndex < drill.pages.length - 1) {
                    const nextPage = drill.pages[drill.selectedPageIndex + 1];
                    const nextPosition = nextPage.getPerformerPosition(performer);
                    if (nextPosition) {
                        const nextPoint = this.getDrillCoordinatesFromStepPosition(nextPosition);
                        const arrow = this._createArrow(currentPoint, nextPoint, 10, this.colors.nextPositionLine);
                        arrow.data.isNextPositionArrow = true;
                        arrow.data.performer = performer;
                        arrow.children.forEach((child) => {
                            child.data.isNextPositionArrow = true;
                            child.data.performer = performer;
                        });
                        this.layers.drill.addChild(arrow);
                    }
                }
            }
        }
        const performerElementsByPerformer = new Map();
        // Draw all the perfomer symbols, but not yet the text
        for (const [performer, position] of performerPositions) {
            const point = this.getDrillCoordinatesFromStepPosition(position);
            // Draw the symbol
            const symbol = new PointText(point);
            symbol.content = performer.symbol;
            symbol.fillColor = this.colors.performers;
            // The symbol size would roughly fit within 4 cells.
            symbol.fontSize = CELL_SIZE * 2.5;
            symbol.justification = "center";
            this.ctx.font = `${symbol.fontSize}px sans-serif`;
            const symbolMetrics = this.ctx.measureText(symbol.content);
            symbol.point.y += symbolMetrics.actualBoundingBoxAscent / 2;
            this.layers.drill.addChild(symbol);
            // Draw a dot in the center of the symbol. This makes it so
            // the location of the symbol is easier to see.
            const dot = new Path.Circle(point, 1);
            dot.fillColor = this.colors.performers;
            this.layers.drill.addChild(dot);
            // Store a reference to the performer, for hit testing later
            symbol.data.performer = performer;
            symbol.data.isSymbol = true;
            dot.data.performer = performer;
            performerElementsByPerformer.set(performer.id, {
                symbol,
                dot,
                performer,
                text: {},
            });
        }
        // Now we will draw the text for the performers. We do this seperately
        // so that we can hit test the symbols to find a position where the text
        // would not overlap with the symbols (if possible).
        for (const [performer, position] of performerPositions) {
            const point = this.getDrillCoordinatesFromStepPosition(position);
            const x = point.x;
            const y = point.y;
            const textOffset = CELL_SIZE * 1.75;
            const performerElements = performerElementsByPerformer.get(performer.id);
            // To find a location for the text, we will try all 4 sides of the performer
            // symbol, and see if any of them are free. We'll prioritize diagonal positions
            const textPositions = [
                new Point(x + textOffset, y + textOffset), // Bottom Right
                new Point(x - textOffset, y + textOffset), // Bottom Left
                new Point(x + textOffset, y - textOffset), // Top Right
                new Point(x - textOffset, y - textOffset), // Top Left
                new Point(x, y - textOffset), // Top
                new Point(x, y + textOffset), // Bottom
                new Point(x - textOffset, y), // Left
                new Point(x + textOffset, y), // Right
            ];
            // Defaults to top
            let textPosition = textPositions[0];
            const fontSize = CELL_SIZE * 2;
            // Use Canvas API to measure text width
            this.ctx.font = `${fontSize}px sans-serif`;
            const textMetrics = this.ctx.measureText(`${performer.number}`);
            for (const position of textPositions) {
                const hit = this.layers.drill.hitTest(position, {
                    tolerance: textMetrics.actualBoundingBoxAscent,
                });
                if (!hit) {
                    textPosition = position;
                    break;
                }
            }
            // Draw the symbol number
            const text = new PointText(textPosition);
            text.content = `${performer.number}`;
            text.fillColor = this.colors.performers;
            text.fontSize = fontSize;
            text.justification = "center";
            // Offset the text to center it
            text.point.y += textMetrics.actualBoundingBoxAscent / 2;
            // This gives an "emboss" effect to the text to make it more readable
            text.strokeColor = new Color(1, 0.25);
            text.strokeWidth = 1;
            // Draw a line from the performer to the text
            const line = new Path.Line(new Point(x, y), textPosition);
            line.strokeColor = this.colors.performers.clone();
            line.strokeColor.alpha = 0.5;
            line.strokeWidth = 2;
            this.layers.drill.addChild(line);
            this.layers.drill.addChild(text);
            // Store a reference to the performer, for hit testing later
            text.data.performer = performer;
            line.data.performer = performer;
            performerElements.text.line = line;
            performerElements.text.text = text;
        }
        // Store the performer elements for later use
        this.lastDrawnPerformersElements = Array.from(performerElementsByPerformer.values());
        this._updatePerformerPositionsBasedOnTransitionPercentage();
        // This also calles _createOverlayLayer which is used for collision
        // detection as well.
        this._updateSelectionRectangle();
    }
    /**
     * Creates an arrow between two points in Paper.js.
     * @param {paper.Point} startPoint - The starting point of the arrow.
     * @param {paper.Point} endPoint - The ending point of the arrow.
     * @param {number} arrowHeadSize - The size of the arrowhead.
     * @param {paper.Color} color - The color of the arrow and arrowhead.
     * @returns {paper.Group} - A group containing the arrow line and arrowhead.
     */
    _createArrow(startPoint, endPoint, arrowHeadSize = 10, color = new paper.Color("black")) {
        if (!startPoint || !endPoint) {
            throw new Error("Both startPoint and endPoint must be provided.");
        }
        // Create the main line
        const arrowLine = new Path.Line({
            from: startPoint,
            to: endPoint,
            strokeColor: color,
        });
        arrowLine.data.isArrowLine = true;
        // Calculate the direction of the arrowhead
        const direction = endPoint.subtract(startPoint).normalize();
        const arrowBase = endPoint.subtract(direction.multiply(arrowHeadSize));
        // Calculate the points for the arrowhead
        const normal = direction
            .rotate(90, new Point(0, 0))
            .multiply(arrowHeadSize * 0.5);
        const arrowLeft = arrowBase.add(normal);
        const arrowRight = arrowBase.subtract(normal);
        // Create the arrowhead
        const arrowHead = new Path({
            segments: [endPoint, arrowLeft, arrowRight],
            closed: true,
            fillColor: color,
        });
        // Group the line and arrowhead
        return new Group([arrowLine, arrowHead]);
    }
    /**
     * This function will draw the drill to the canvas. It will translate the
     * positioning of the standard drill scope to match the canvas size.
     */
    drawToCanvas(boundsRectangle = this.defaultCanvasRectangle.scale(0.95)) {
        // Clear the canvas
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.canvasPaper.project.clear();
        this.canvasPaper.activate();
        // Set the canvas background
        const backgroundLayer = new Layer();
        // Clear the background for the full canvas, regardless of the bounds
        const background = new Path.Rectangle(this.defaultCanvasRectangle);
        background.fillColor = this.colors.background;
        backgroundLayer.addChild(background);
        // Copy all items from the drill layer to the canvas layer,
        // while translating their positions to match the canvas size.
        const layers = [
            this.layers.mesh,
            this.layers.yardLines,
            this.layers.drill,
            this.layers.overlays,
        ];
        const canvasLayer = new Layer();
        for (const layer of layers) {
            for (const item of layer.children) {
                canvasLayer.addChild(item.clone());
            }
        }
        // Fit the layer within the rectangle
        canvasLayer.fitBounds(boundsRectangle);
        this.currentDrawBounds = canvasLayer.bounds;
    }
}
