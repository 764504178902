import MarketingNavbar from "../components/marketingNav";
import { marked } from "marked";
import { Helmet } from "react-helmet";
import {
  Cloud,
  GraduationCap,
  Heart,
  Music4,
  Pencil,
  Users,
  Users2,
} from "lucide-react";
import Footer from "../components/footer";

export default function AboutPage() {
  const benefits = [
    {
      title: "Marching Band Directors",
      description:
        "Spend less time on logistics and more time creating great drill. With DrillFlo's cloud-based platform, you can collaborate with your staff, visualize your designs, and ensure your drill is in perfect alignment—without the headache of complicated software.",
      icon: Users2,
    },
    {
      title: "Drill Designers",
      description:
        "Whether you're designing for high school, college, or advanced ensembles, DrillFlo provides all the tools you need to create precise, dynamic drill formations. Start designing faster with easy-to-use features and intuitive controls.",
      icon: Pencil,
    },
    {
      title: "Music Educators",
      description:
        "Integrate drill design seamlessly with your music program. DrillFlo supports performance-based design, allowing you to synchronize visual and musical elements for a comprehensive approach to teaching and performance.",
      icon: GraduationCap,
    },
    {
      title: "Students and Hobbyists",
      description:
        "If you're just starting your drill design journey, DrillFlo is the perfect platform to learn and grow. The user-friendly interface and built-in guides make it easy for beginners to dive in and start creating drill in no time.",
      icon: Heart,
    },
  ];
  return (
    <>
      <Helmet>
        <title>About DrillFlo ✨</title>
      </Helmet>
      <MarketingNavbar />
      <div className="relative overflow-hidden bg-gradient-to-b from-primary/5 to-background pt-24 pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-primary sm:text-6xl">
              The Future of Marching Band Drill Design Software
            </h1>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              DrillFlo is a cutting-edge, web-based marching band drill design
              platform crafted for the next generation of drill designers.
              Whether you're a student designer, an experienced director, or a
              professional in the marching arts, DrillFlo is here to empower
              your creative process, streamline your workflow, and elevate the
              way you design, visualize, and share drill.
            </p>
          </div>
          <div className="mt-16 flow-root sm:mt-24">
            <div className="relative rounded-xl bg-card p-8 ring-1 ring-border/10 sm:p-12">
              <div className="absolute -inset-px rounded-xl border-2 border-dashed border-border" />
              <div className="relative grid grid-cols-1 gap-6 sm:grid-cols-3">
                {[
                  {
                    icon: Music4,
                    title: "Intuitive Tools",
                    description:
                      "Create Stunning Drill Designs. Our intuitive drag-and-drop interface, dynamic movement features, and powerful visualization tools make creating marching band drill a breeze. Say goodbye to complicated software and hello to a streamlined design experience.",
                  },
                  {
                    icon: Users,
                    title: "Collaborative",
                    description:
                      "Collaborate in Real-Time. Work together with your team, staff, or fellow designers in real-time, making edits and updates instantly—whether you're in the studio, on the field, or on the go.",
                  },
                  {
                    icon: Cloud,
                    title: "Cloud Storage System",
                    description:
                      "Stay Organized and Efficient. Organize your work with ease and always stay on top of your projects with version control and history tracking features. You’ll never lose your progress again.                   ",
                  },
                ].map((feature, index) => (
                  <div key={index} className="pt-6">
                    <div className="flow-root rounded-lg bg-background px-6 pb-8">
                      <div className="-mt-6">
                        <div className="inline-flex items-center justify-center rounded-md bg-primary p-3 shadow-lg">
                          <feature.icon
                            className="h-6 w-6 text-primary-foreground"
                            aria-hidden="true"
                          />
                        </div>
                        <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-primary">
                          {feature.title}
                        </h3>
                        <p className="mt-5 text-base leading-7 text-muted-foreground">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-24 bg-background">
        <div className="container px-6 mx-auto">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-8">
              The Most Powerful, Accessible Marching Band Drill Design Software
            </h2>
            <p className="text-lg leading-8 text-muted-foreground">
              DrillFlo offers professional-grade tools that make designing
              marching band drill easier than ever before. Competing with the
              traditional tools, DrillFlo stands out with its cloud-based
              flexibility, real-time collaboration, and intuitive interface.
              Designed with the modern marching band designer in mind, DrillFlo
              puts control in your hands and creativity at the forefront.
            </p>
          </div>
        </div>
      </section>
      <section className="py-24 bg-muted/50">
        <div className="container px-6 mx-auto">
          <div className="max-w-2xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Who Can Benefit from DrillFlo?
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 max-w-7xl mx-auto">
            {benefits.map((benefit) => (
              <div
                key={benefit.title}
                className="flex flex-col bg-card rounded-lg p-8 shadow-sm"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-2 rounded-lg bg-primary/10">
                    <benefit.icon className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="text-xl font-semibold">{benefit.title}</h3>
                </div>
                <p className="text-muted-foreground leading-relaxed">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
