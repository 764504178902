import {
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignEndHorizontal,
  AlignEndVertical,
  AlignStartHorizontal,
  AlignStartVertical,
} from "lucide-react";

const RightNav = ({
  alignPerformersHorizontally,
  alignPerformersVertically,
  alignPerformersToLeft,
  stepInterval,
  setStepInterval,
  setSnapToGrid,
  setPerformerInterval,
  rotation,
  handleRotationChange,
  snapToGrid,
  alignPerformersToRight,
  alignPerformersToTop,
  alignPerformersToBottom,
  showMovementArrows,
  setShowMovementArrows,
}) => (
  <div
    id="default-sidebar"
    class="z-40 w-40 h-[50%]  transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar"
  >
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 rounded-md border-l border-b border-t shadow-lg">
      <ul class="space-y-2 font-medium text-left">
        <div className="flex flex-row  bg-gray-300 items-center m-auto justify-center rounded-md ">
          <li>
            <button
              onClick={() => alignPerformersToTop()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Top Align Performers"
            >
              <AlignStartHorizontal className="w-4 h-4" />{" "}
            </button>
          </li>
          <li>
            <button
              onClick={() => alignPerformersHorizontally()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Center Align Performers"
            >
              <AlignCenterHorizontal className="w-4 h-4" />{" "}
            </button>
          </li>
          <li>
            <button
              onClick={() => alignPerformersToBottom()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Bottom Align Performers"
            >
              <AlignEndHorizontal className="w-4 h-4" />{" "}
            </button>
          </li>
        </div>
        <div className="flex flex-row  bg-gray-300 items-center m-auto justify-center rounded-md ">
          <li>
            <button
              onClick={() => alignPerformersToLeft()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Left Align Performers"
            >
              <AlignStartVertical className="w-4 h-4" />{" "}
            </button>
          </li>
          <li>
            <button
              onClick={() => alignPerformersVertically()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Center Align Performers"
            >
              <AlignCenterVertical className="w-4 h-4" />{" "}
            </button>
          </li>
          <li>
            <button
              onClick={() => alignPerformersToRight()}
              className="flex flex-row bg-gray-300 p-1 hover:bg-gray-100 transition-all duration-200"
              title="Right Align Performers"
            >
              <AlignEndVertical className="w-4 h-4" />{" "}
            </button>
          </li>
        </div>
        {/* <li>
          <div className="bg-gray-300 items-center m-auto justify-center rounded-md p-2">
            <label>
              Step Interval:
              <input
                type="number"
                className="w-full pl-4 relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                value={stepInterval}
                onChange={(e) => setStepInterval(parseInt(e.target.value) || 1)}
                min="1"
              />
            </label>
            <button
              onClick={setPerformerInterval}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mt-2 mx-auto rounded"
            >
              Set Interval
            </button>
          </div>
        </li> */}
        {/* <li>
          <div>
            <label>
              Rotate:
              <input
                type="range"
                min="-90"
                max="90"
                value={rotation}
                onChange={handleRotationChange}
                className="w-full"
              />
              <span>{rotation}°</span>
            </label>
          </div>
        </li> */}

        <li className="flex flex-row text-sm place-content-evenly  align-middle my-auto">
          Snap to Grid:
          <label
            for="SnapToGrid"
            class="relative my-auto inline-block h-6 w-12 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-emerald-500"
          >
            <input
              type="checkbox"
              id="SnapToGrid"
              class="peer sr-only"
              checked={snapToGrid}
              onChange={(e) => setSnapToGrid(e.target.checked)}
            />

            <span class="absolute inset-y-0 start-0 m-1 size-4 rounded-full bg-white transition-all peer-checked:start-5"></span>
          </label>
        </li>
        <li className="flex flex-row text-sm place-content-evenly  align-middle my-auto">
          Path Lines
          <label
            for="showMovementArrows"
            class="relative my-auto inline-block h-6 w-12 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-emerald-500"
          >
            <input
              type="checkbox"
              id="showMovementArrows"
              class="peer sr-only"
              checked={showMovementArrows}
              onChange={(e) => setShowMovementArrows(e.target.checked)}
            />

            <span class="absolute inset-y-0 start-0 m-1 size-4 rounded-full bg-white transition-all peer-checked:start-5"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
);

export default RightNav;
