import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import { Play, Pause, Scissors } from "lucide-react";

interface AudioWaveformProps {
  audioUrl: string;
  onTrimComplete: (start: number, end: number) => void;
}

const AudioWaveform = ({ audioUrl, onTrimComplete }: AudioWaveformProps) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const regionsPlugin = useRef<RegionsPlugin | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeRegion, setActiveRegion] = useState<{
    start: number;
    end: number;
  } | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!waveformRef.current) return;

    // Only create a new instance if one doesn't exist
    if (!wavesurfer.current) {
      const ws = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ddd",
        progressColor: "#4A90E2",
        backend: "MediaElement",
        responsive: true,
        height: 100,
      });
      wavesurfer.current = ws;

      // Initialize regions plugin
      regionsPlugin.current = ws.registerPlugin(RegionsPlugin.create());

      // Set up event listeners
      ws.on("ready", () => {
        setIsReady(true);
        setDuration(ws.getDuration());
      });

      ws.on("timeupdate", () => {
        setCurrentTime(ws.getCurrentTime());
      });

      ws.on("play", () => setIsPlaying(true));
      ws.on("pause", () => setIsPlaying(false));

      // Add region creation on drag
      ws.on("interaction", () => {
        if (regionsPlugin.current) {
          regionsPlugin.current.clearRegions();
          regionsPlugin.current.addRegion({
            start: ws.getCurrentTime(),
            end: Math.min(ws.getCurrentTime() + 1, ws.getDuration()),
            color: "rgba(74, 144, 226, 0.3)",
            drag: true,
            resize: true,
          });
        }
      });

      // Update active region when region is updated
      if (regionsPlugin.current) {
        regionsPlugin.current.on("region-updated", (region) => {
          setActiveRegion({
            start: region.start,
            end: region.end,
          });
        });
      }
    }

    // Load audio whenever audioUrl changes
    if (wavesurfer.current && audioUrl) {
      wavesurfer.current.load(audioUrl);
    }

    // Cleanup function
    return () => {
      // Only perform cleanup when component is unmounting
      if (!waveformRef.current && wavesurfer.current) {
        if (isPlaying) {
          wavesurfer.current.stop();
        }
        if (regionsPlugin.current) {
          regionsPlugin.current.clearRegions();
        }
        wavesurfer.current.destroy();
        wavesurfer.current = null;
        regionsPlugin.current = null;
        setIsReady(false);
        setIsPlaying(false);
        setActiveRegion(null);
      }
    };
  }, [audioUrl]);

  const togglePlayPause = () => {
    if (wavesurfer.current && isReady) {
      wavesurfer.current.playPause();
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleTrim = () => {
    if (activeRegion) {
      onTrimComplete(activeRegion.start, activeRegion.end);
    }
  };

  return (
    <div className="w-full space-y-4">
      <div ref={waveformRef} className="w-full" />

      <div className="flex items-center justify-between px-2">
        <div className="flex items-center gap-4">
          <button
            onClick={togglePlayPause}
            disabled={!isReady}
            className="rounded-full p-2 hover:bg-gray-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isPlaying ? (
              <Pause className="size-5" />
            ) : (
              <Play className="size-5" />
            )}
          </button>
          <span className="text-sm text-gray-600">
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </div>

        <button
          onClick={handleTrim}
          disabled={!activeRegion || !isReady}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Scissors className="size-4" />
          Trim
        </button>
      </div>

      {activeRegion && (
        <div className="text-sm text-gray-600">
          Selected: {formatTime(activeRegion.start)} -{" "}
          {formatTime(activeRegion.end)}
        </div>
      )}
    </div>
  );
};

export default AudioWaveform;
