import React from "react";
import { HardDrive, AlertCircle } from "lucide-react";

interface StorageBannerProps {
  currentUsage: number;
  storageLimit: number;
  onUpgradeClick: () => void;
}

export default function StorageBanner({
  currentUsage,
  storageLimit,
  onUpgradeClick,
}: StorageBannerProps) {
  const usagePercentage = (currentUsage / storageLimit) * 100;
  const isNearLimit = usagePercentage >= 90;

  if (!isNearLimit) return null;

  return (
    <div className="bg-orange-50 border-b border-orange-100">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2 text-orange-600">
              <AlertCircle className="h-5 w-5" />
              <span className="font-medium">Storage Almost Full</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <HardDrive className="h-4 w-4" />
              <span className="text-sm">
                {currentUsage} drill{currentUsage > 1 && "s"} of {storageLimit}{" "}
                drills used
              </span>
            </div>
          </div>
          <button
            onClick={onUpgradeClick}
            className="px-4 py-1.5 bg-orange-600 text-white rounded-lg text-sm font-medium hover:bg-orange-700 transition-colors"
          >
            Upgrade Now
          </button>
        </div>
        <div className="mt-2 h-1 bg-orange-100 rounded-full overflow-hidden">
          <div
            className="h-full bg-orange-500 rounded-full transition-all"
            style={{ width: `${usagePercentage}%` }}
          />
        </div>
      </div>
    </div>
  );
}
