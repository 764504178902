// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  Timestamp,
  setDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYmu7v3lUHzDW-c1muymm5dS7DedJQCfU",
  authDomain: "cloud-drill-design.firebaseapp.com",
  projectId: "cloud-drill-design",
  storageBucket: "cloud-drill-design.appspot.com",
  messagingSenderId: "254396605382",
  appId: "1:254396605382:web:5c6116f71b0e7f220d48c7",
  measurementId: "G-JQ71GKCFYS",
};

// createUserWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // Signed up
//     const user = userCredential.user;
//     // ...
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ..
//   });

// signInWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // Signed in
//     const user = userCredential.user;
//     // ...
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//   });

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    toast.error("Incorrect email or password");
  }
};
const logout = async () => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  await delay(1000);
  // toast("You have been logged out 👋");
  signOut(auth);
};
const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  password
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      firstName,
      lastName,
      authProvider: "email",
      email,
      onboarded: "N",
      created: new Date(),
    });
    // Navigate();
  } catch (err) {
    // toast.error("Something went wrong when tryin to register");
    // alert(err.message);
  }
};

const getUserPlanData = async (user) => {
  const subscriptionsRef = collection(db, "users", user, "subscriptions");

  // Query for active subscriptions, ordered by start date descending
  const activeSubscriptionsQuery = query(
    subscriptionsRef,
    where("status", "in", ["trialing", "active"])
  );

  try {
    // Execute the query
    const querySnapshot = await getDocs(activeSubscriptionsQuery);

    // Collect all active subscription data
    const activeSubscriptions = [];
    querySnapshot.forEach((doc) => {
      activeSubscriptions.push(doc.data());
    });

    // Get the most recent subscription
    const mostRecentSubscription = activeSubscriptions[0];

    console.warn(mostRecentSubscription);
    // Extract plan details from the most recent subscription
    const planKey = mostRecentSubscription?.items[0]?.price?.lookup_key;
    const planStatus = mostRecentSubscription?.items[0]?.plan?.active;

    // Return the most recent active subscription data, or null if none are found
    return mostRecentSubscription
      ? { planKey: planKey, planActive: planStatus }
      : { planKey: null, planActive: false };
  } catch (error) {
    console.error("Error fetching subscription data:", error);
    throw error;
  }
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider();

export {
  db,
  auth,
  functions,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
  getUserPlanData,
  googleProvider,
};
