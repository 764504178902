import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  // signInWithGoogle,
} from "../services/firebase";
import { db } from "../services/firebase";
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import MarketingNavbar from "../components/marketingNav";
import { toast } from "react-hot-toast";
import WaitlistForm from "../components/waitlist";

// import toast from "react-hot-toast";
// import { KeyIcon } from "@heroicons/react/solid";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [codeExists, setCodeExists] = useState(false);
  const [codesFound, setCodesFound] = useState(false);

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [hasAccessCode, setHasAccessCode] = useState(false);
  const [user, loading, error] = useAuthState(auth);

  const register = () => {
    if (!firstName) toast.error("Please Enter Your Name");
    if (!email) toast.error("Please Enter Your Email");
    if (!password) toast.error("Please Enter a Password");
    registerWithEmailAndPassword(firstName, lastName, email, password);
  };
  const navigate = useNavigate();

  const handleAccessCode = async (event) => {
    event.preventDefault();
    const docRef = doc(db, "invitationCodes", accessCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const codeData = docSnap.data();
      setCodeExists(true);
      checkCodeValid();
    } else return toast.error("Invalid Invitation Code");
  };

  const checkCodeValid = async () => {
    try {
      const q = query(
        collection(db, "invitationCodes"),
        where("__name__", "==", accessCode)
      );
      const docFromDB = await getDocs(q);
      const data = docFromDB.docs[0].data();
      console.log(data);

      const docRef = doc(db, "invitationCodes", accessCode);

      if (!data.codeUsed)
        return (
          setHasAccessCode(true),
          toast.success("Invitation Code is Valid"),
          await updateDoc(docRef, { codeUsed: true })
        );
      if (data.codeUsed)
        return (
          setHasAccessCode(false), toast.error("Invitation Code Has Been Used")
        );
    } catch (err) {
      console.error(err);
      toast.error("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // if (codeExists) return checkCodeValid();
    if (user) navigate("/my-designs");
  }, [user, loading, codeExists]);

  return (
    <div className="">
      <MarketingNavbar />
      <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
          <div>
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Create your Account
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
              already have an account?
              <Link
                to="/login"
                class="font-medium text-purple-600 hover:text-purple-500"
              >
                {" "}
                log in{" "}
              </Link>
            </p>
          </div>
          {hasAccessCode ? (
            <div class="mt-8 space-y-6">
              <input type="hidden" name="remember" value="true" />
              <div class="rounded-md shadow-sm -space-y-px">
                <div>
                  <label for="first-name" class="sr-only">
                    First Name
                  </label>
                  <input
                    id="first-name"
                    name="name"
                    type="name"
                    autocomplete="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="First Name"
                  />
                </div>
                <div>
                  <label for="last-name" class="sr-only">
                    Last name
                  </label>
                  <input
                    id="last-name"
                    name="lastname"
                    type="lastName"
                    autocomplete="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Last Name"
                  />
                </div>
                <div>
                  <label for="email-address" class="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autocomplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label for="password" class="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Password"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center"></div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={register}
                  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      class="h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Create My Account
                </button>{" "}
                {/* <button onClick={signInWithGoogle} class="blank-button pb-8">
                <img src={GoogleLogo} alt="Sign in with Google" />
              </button>{" "} */}
              </div>
            </div>
          ) : (
            <div>
              <h3 className="text-blue-600 font-bold text-2xl">
                Beta Test Registration
              </h3>
              <p className="pt-4 ">
                DrillFlo is currently in a Beta Test. You can create an account
                with an Invitation from an existing user.
              </p>
              <div>
                <form onSubmit={handleAccessCode}>
                  <label for="access-code" class="sr-only">
                    Enter Invitation Code
                  </label>
                  <input
                    id="access-code"
                    name="access-code"
                    type="name"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    required
                    class="appearance-none rounded-none relative mt-4 block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    placeholder="Invitation Code"
                  />{" "}
                  <button
                    type="submit"
                    class="group relative w-full flex justify-center py-2 mt-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                      {/* <KeyIcon className="h-5 w-5 color: inherit	" /> */}
                    </span>
                    Check Code
                  </button>{" "}
                </form>
              </div>
              <p className="pt-8 font-bold">Don't have an Access Code?</p>
              <WaitlistForm />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default RegisterPage;
