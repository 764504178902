import React, { useState } from "react";
import {
  Mail,
  BookOpen,
  Lightbulb,
  History,
  X,
  MessageSquarePlus,
  CircleHelp,
  Sparkles,
} from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
// import { cn } from "../../lib/utils";
import { EmailForm } from "./emailForm.tsx";
import { ChangelogHighlights } from "./changelog.tsx";

export function SupportWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<"email" | "changelog">("email");

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen ? (
        <div className="bg-white rounded-lg shadow-xl w-96 border border-gray-200">
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <h2 className="text-lg font-semibold">Support & Resources</h2>
            <button
              // variant="ghost"
              size="icon"
              onClick={() => setIsOpen(false)}
              className="h-8 w-8 hover:bg-gray-100 hover:text-gray-900 text-center  inline-flex items-center justify-center"
            >
              <X className="h-4 w-4" />
            </button>
          </div>

          <div className="flex border-b border-gray-200">
            <button
              // variant="ghost"
              className={`flex-1 p-2 hover:bg-gray-100 hover:text-gray-900 text-center  inline-flex items-center justify-center
                ${activeTab === "email" && "bg-gray-100"}
              `}
              onClick={() => setActiveTab("email")}
            >
              <Mail className="h-4 w-4 mr-2" />
              Support
            </button>
            <button
              // variant="ghost"
              className={`flex-1 p-2 hover:bg-gray-100 hover:text-gray-900 text-center  inline-flex items-center justify-center
                ${activeTab === "changelog" && "bg-gray-100"}
              `}
              onClick={() => setActiveTab("changelog")}
            >
              <Sparkles className="h-4 w-4 mr-2" />
              New Features
            </button>
          </div>

          <div className="p-4">
            {activeTab === "email" ? <EmailForm /> : <ChangelogHighlights />}
          </div>

          <div className="p-4 bg-gray-50 rounded-b-lg border-t border-gray-200">
            <div className="space-y-2">
              <a
                href="/guidebook"
                className="flex items-center text-sm text-gray-600 hover:text-gray-900"
              >
                <BookOpen className="h-4 w-4 mr-2" />
                Guide & Resources
              </a>
              <a
                href="https://drillflo.canny.io/feature-requests"
                className="flex items-center text-sm text-gray-600 hover:text-gray-900"
              >
                <Lightbulb className="h-4 w-4 mr-2" />
                Submit Feature Request
              </a>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="h-12 w-12 rounded-full shadow-lg bg-blue-500 text-white text-center  inline-flex items-center justify-center"
        >
          <CircleHelp className="h-6 w-6" />
        </button>
      )}
    </div>
  );
}
