import MarketingNavbar from "../components/marketingNav";

export default function MobileDisabled() {
  return (
    <>
      <MarketingNavbar />
      <h1 className="font-bold text-2xl mt-8">Page Unavailable</h1>
      <p className="font-normal text-lg pt-8 w-3/5 m-auto">
        Oops! <br /> Viewing drill is not yet available on Mobile Devices.
        <br /> Please access it from a tablet, laptop, or desktop for the best
        experience.
      </p>
    </>
  );
}
