/**
 * @file Performer.ts
 * @description Represents a performer in the drill, which can be a player, a band member, or any other entity that moves
 * within the drill formation. The Performer class will handle the positioning, movement, symbols, and other attributes.
 * @author Marcus Dubreuil
 */
import { v4 as uuidv4 } from "uuid";
export default class Performer {
    drill;
    id;
    _number;
    _name;
    _symbol;
    constructor(drill, name, number, symbol, id) {
        this.drill = drill;
        this._name = name;
        this._number = number;
        this._symbol = symbol;
        // If no id is provided, generate a new Unique ID
        this.id = id || uuidv4();
    }
    /**
     * @returns {string} The name of the performer.
     */
    get name() {
        return this._name;
    }
    /**
     * @returns {PerformerSymbol} The symbol representing the performer.
     */
    get symbol() {
        return this._symbol;
    }
    /**
     * @returns {number} The number of the performer.
     */
    get number() {
        return this._number;
    }
    /**
     * Sets the number of the performer.
     */
    set number(newNumber) {
        this._number = newNumber;
        this.drill.draw();
    }
    /**
     * Gets the current position of the performer on the selected page.
     * @returns {StepPosition | undefined} The current position of the performer, or undefined if not found.
     */
    get position() {
        return this.drill.selectedPage.getPerformerPosition(this);
    }
    /**
     * Sets the name of the performer.
     */
    set name(newName) {
        this._name = newName;
        this.drill.draw();
    }
    /**
     * Sets the symbol representing the performer.
     */
    set symbol(newSymbol) {
        this._symbol = newSymbol;
        this.drill.draw();
    }
    /**
     * Sets the position of the performer on the selected page.
     * @param {StepPosition} newPosition - The new position to set for the performer.
     */
    set position(newPosition) {
        this.drill.selectedPage.setPerformerPosition(this, newPosition);
        this.drill.selectedPage.drawIfSelected();
    }
}
