import { useState, useEffect } from "react";
import { db, auth } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { User } from "firebase/auth";

interface DocumentAccess {
  hasAccess: boolean;
  isLoading: boolean;
  error: string | null;
  role: "owner" | "editor" | "viewer" | null;
  data: any | null;
}

export function useDocumentAccess(documentId: string): DocumentAccess {
  const [access, setAccess] = useState<DocumentAccess>({
    hasAccess: false,
    isLoading: true,
    error: null,
    role: null,
    data: null,
  });

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const checkAccess = async (user: User | null) => {
      if (!user) {
        setAccess({
          hasAccess: false,
          isLoading: false,
          error: "Please sign in to access this document",
          role: null,
          data: null,
        });
        return;
      }

      try {
        const docRef = doc(db, "designs", documentId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          setAccess({
            hasAccess: false,
            isLoading: false,
            error: "Document not found",
            role: null,
            data: null,
          });
          return;
        }

        const data = docSnap.data();
        const userEmail = user.email;

        // Check if user is owner
        if (data.createdBy === user.uid) {
          setAccess({
            hasAccess: true,
            isLoading: false,
            error: null,
            role: "owner",
            data,
          });
          return;
        }

        // Check if user is a collaborator
        const collaborator = data.collaborators?.find(
          (c: { email: string }) => c.email === userEmail
        );

        if (collaborator) {
          setAccess({
            hasAccess: true,
            isLoading: false,
            error: null,
            role: collaborator.role,
            data,
          });
          return;
        }

        // No access
        setAccess({
          hasAccess: false,
          isLoading: false,
          error: "You do not have access to this document",
          role: null,
          data: null,
        });
      } catch (error) {
        setAccess({
          hasAccess: false,
          isLoading: false,
          error: "Error checking document access",
          role: null,
          data: null,
        });
      }
    };

    unsubscribe = auth.onAuthStateChanged(checkAccess);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [documentId]);

  return access;
}
