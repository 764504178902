import { Helmet } from "react-helmet";
import MarketingNavbar from "../components/marketingNav";
import { auth, getUserPlanData } from "../services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { db, functions } from "../services/firebase";
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/loadingSpinner";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function MyAccount() {
  const [userData, setUserData] = useState(null); // Store user data
  const [loading, setLoading] = useState(false); // Store user data
  const [userPlanInfo, setUserPlanInfo] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "United States",
    streetAddress: "",
    city: "",
    region: "",
    postalCode: "",
    plan: "base", // Default plan value
    planActive: false, // Default checkbox value
  });

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData();
      } else {
        alert("No user found, please log in");
        navigate("/login"); // Or redirect to login
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        const userData = { id: userDoc.id, ...userDoc.data() };
        setUserData(userData);
        setFormData({
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          email: userData.email || "",
          country: userData.country || "United States",
          streetAddress: userData.streetAddress || "",
          city: userData.city || "",
          region: userData.region || "",
          postalCode: userData.postalCode || "",
          plan: userData.plan || "base", // Set the plan value
          planActive: userData.planActive || false, // Set the planActive value
        });
        const planData = await getUserPlanData(auth.currentUser.uid);
        setUserPlanInfo(planData);
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, // Handle checkbox correctly
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userRef = doc(db, "users", userData.id);
      await updateDoc(userRef, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        country: formData.country,
        streetAddress: formData.streetAddress,
        city: formData.city,
        region: formData.region,
        postalCode: formData.postalCode,
        plan: formData.plan,
        planActive: formData.planActive,
      });
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating user data: ", error);
    }
  };
  const getPortalLink = async (event) => {
    event.preventDefault();

    try {
      // Show loading state
      setLoading(true);

      const createPortalLink = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await createPortalLink({
        returnUrl: window.location.origin,
      });

      if (!data?.url) {
        throw new Error("No portal URL received from server");
      }

      // Redirect to the portal
      window.location.assign(data.url);
    } catch (error) {
      console.error("Portal link error:", error);
      // Show user-friendly error message
      alert(
        "Unable to access the billing portal. Please try again or contact support if the problem persists."
      );
    } finally {
      setLoading(false);
    }
  };
  if (loading)
    return (
      <>
        {" "}
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  return (
    <>
      <Helmet>
        <title>DrillFlo ✨ My Account</title>
      </Helmet>
      <MarketingNavbar />

      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            My Account
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Personal Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Use a permanent address where you can receive mail.
                </p>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        id="first-name"
                        name="firstName"
                        type="text"
                        autoComplete="given-name"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        id="last-name"
                        name="lastName"
                        type="text"
                        autoComplete="family-name"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                        <option value="Mexico">Mexico</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Street address
                    </label>
                    <div className="mt-2">
                      <input
                        id="street-address"
                        name="streetAddress"
                        type="text"
                        autoComplete="street-address"
                        value={formData.streetAddress}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <div className="mt-2">
                      <input
                        id="city"
                        name="city"
                        type="text"
                        autoComplete="address-level2"
                        value={formData.city}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      State / Province
                    </label>
                    <div className="mt-2">
                      <input
                        id="region"
                        name="region"
                        type="text"
                        autoComplete="address-level1"
                        value={formData.region}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      ZIP / Postal code
                    </label>
                    <div className="mt-2">
                      <input
                        id="postal-code"
                        name="postalCode"
                        type="text"
                        autoComplete="postal-code"
                        value={formData.postalCode}
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className=" py-8">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                    Manage Plan
                  </h1>
                </div>
                <h3>Current Plan</h3>
                {userPlanInfo && (
                  <p className="font-bold text-xl">
                    {userPlanInfo &&
                      userPlanInfo.planKey == "standard_monthly" &&
                      userPlanInfo.planActive &&
                      "Standard Plan Paid Monthly"}
                    {userPlanInfo &&
                      userPlanInfo.planKey == "pro_monthly" &&
                      userPlanInfo.planActive &&
                      "Professional Plan Paid Monthly"}
                    {userPlanInfo &&
                      userPlanInfo.planKey == "standard_annual" &&
                      userPlanInfo.planActive &&
                      "Standard Plan Paid Yearly"}
                    {userPlanInfo &&
                      userPlanInfo.planKey == "free" &&
                      userPlanInfo.planActive &&
                      "Free Plan"}
                    {userPlanInfo &&
                      userPlanInfo.planKey == "pro_annual" &&
                      userPlanInfo.planActive &&
                      "Professional Plan Paid Yearly"}
                    {!userPlanInfo.planActive && "Inactive"}
                  </p>
                )}
                <button
                  onClick={getPortalLink}
                  className="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring active:bg-indigo-500"
                >
                  Billing Portal
                </button>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}
