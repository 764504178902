import React, { useState } from "react";
import {
  MoreVertical,
  Clock,
  Star,
  Pencil,
  Copy,
  Archive,
  Trash2,
} from "lucide-react";
import { deleteDoc, doc } from "firebase/firestore";

interface ProjectCardProps {
  id: number;
  title: string;
  lastUpdated: string;
  thumbnail: string;
  favorite: boolean;
  status: string;
  link: string;
}

export default function ProjectCard({
  id,
  title,
  lastUpdated,
  thumbnail,
  favorite,
  status,
  link,
  setSelectedDrill,
  setShowDeleteModal,
}: ProjectCardProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favorite);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "complete":
        return "bg-green-100 text-green-800";
      case "in progress":
        return "bg-blue-100 text-blue-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <a
      className="group bg-white rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-all"
      href={`/designs/${link}`}
    >
      <div className="aspect-video relative overflow-hidden rounded-t-xl">
        <img
          src={thumbnail}
          alt={title}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-colors">
          {/* <button
            onClick={() => setIsFavorite(!isFavorite)}
            className="absolute top-2 left-2 p-1.5 bg-white/90 rounded-full hover:bg-white transition-colors"
          >
            <Star
              className={`h-4 w-4 ${
                isFavorite ? "fill-yellow-400 text-yellow-400" : "text-gray-600"
              }`}
            />
          </button> */}
          <div className="absolute top-2 right-2">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="p-1.5 bg-white/90 rounded-full hover:bg-white transition-colors"
            >
              <MoreVertical className="h-4 w-4 text-gray-600" />
            </button>
            {showMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1">
                <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2">
                  <Pencil className="h-4 w-4" /> Edit
                </button>
                <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2">
                  <Copy className="h-4 w-4" /> Duplicate
                </button>
                <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2">
                  <Archive className="h-4 w-4" /> Archive
                </button>
                <button
                  className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50 flex items-center gap-2"
                  onClick={() => {
                    setSelectedDrill(); // Set the selected drill
                    setShowDeleteModal(true); // Show the delete confirmation modal
                  }}
                >
                  <Trash2 className="h-4 w-4" /> Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-semibold text-gray-900">{title}</h3>
          <span
            className={`text-xs px-2 py-1 rounded-full ${getStatusColor(
              status
            )}`}
          >
            {status}
          </span>
        </div>
        <div className="flex items-center text-sm text-gray-500">
          <Clock className="h-4 w-4 mr-1" />
          Last updated {lastUpdated}
        </div>
      </div>
    </a>
  );
}
