import { useState } from "react";
import { db } from "../services/firebase"; // Assuming you have this configured already
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export default function WaitlistForm() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "waitlistEmails"), {
        email,
        timestamp: serverTimestamp(),
      });
      setSuccessMessage("Thank you for joining the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error adding email to waitlist: ", error);
      alert("There was an issue adding you to the waitlist. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="mt-16 bg-white p-8 shadow-xl rounded-lg animate-slidein opacity-0 [--slidein-delay:1000ms]">
      <h4 className="font-bold text-lg mb-4">Join the Waitlist</h4>
      {successMessage ? (
        <p className="text-green-600 font-semibold">{successMessage}</p>
      ) : (
        <form onSubmit={handleEmailSubmit}>
          <label
            htmlFor="UserEmail"
            className="relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-purple-600 focus-within:ring-1 focus-within:ring-purple-600"
          >
            <input
              type="email"
              id="UserEmail"
              placeholder="Email"
              className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <span className="absolute start-3 top-3 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-3 peer-focus:text-xs">
              Email
            </span>
          </label>
          <button
            type="submit"
            className="block w-full rounded-lg bg-gradient-to-r from-blue-300 via-blue-500 to-purple-600 px-5 py-3 text-sm font-medium text-white mt-8 transition-all ease-in-out hover:scale-105 hover:shadow-md"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Be the First to Know"}
          </button>
        </form>
      )}
    </div>
  );
}
