// import mbDesignMember from "assets/images/mb_design_member.png";
export default function NoUploadsYet({ createNewDrill }) {
  return (
    <div className="mx-auto max-w-screen-xl md:px-4 w-full lg:items-center">
      <div className="bg-gradient-to-r from-[#143e8817] to-[#02020629] border border-[#bacfe356] bg-opacity-10 rounded-2xl md:m-8 shadow-sm md:h-60 h-80 flex items-center justify-between  md:p-4 ">
        <div className="text-lg font-semibold">
          <h3 className="font-bold text-3xl text-gray-700 md:pt-0 pt-8 px-4 pb-8">
            No Drill Designs Yet?{" "}
            <span className=" text-purple-600">No Problem.</span>
          </h3>
          <p className="font-normal text-md text-gray-600 px-4">
            Get started with your first design and bring your ideas to life.
          </p>
          <div className="gap-4 flex flex-col md:flex-row pt-8 px-4 ">
            <button
              className="inline-block rounded border border-indigo-600 bg-gradient-to-r from-blue-500 to-purple-600 px-12 py-4  text-sm font-medium text-white text-center hover:bg-transparent hover:scale-105 hover:shadow-2xl transition-all ease-in-out   focus:outline-none focus:ring active:text-indigo-500"
              onClick={createNewDrill}
            >
              New Design
            </button>

            <a
              class="inline-block rounded border border-indigo-600 px-12 py-3 text-sm font-medium text-indigo-600 hover:bg-gradient-to-l from-blue-500 to-purple-600 text-center hover:text-white focus:outline-none hover:scale-105 hover:shadow-2xl transition-all ease-in-out   focus:ring active:bg-indigo-500"
              href="#"
            >
              Browse Templates
            </a>
          </div>{" "}
        </div>
        <img
          src="/assets/images/mb_design_member.png"
          className="md:w-80 hidden md:inline "
        />
      </div>
    </div>
  );
}
