export function analyzeSpacing(positions) {
  if (positions.length < 2) {
    return { direction: "horizontal", currentSpacing: 0, isUniform: true };
  }

  // Sort positions by both coordinates
  const sortedH = [...positions].sort(
    (a, b) => a.horizontalStepsFrom50 - b.horizontalStepsFrom50
  );
  const sortedV = [...positions].sort(
    (a, b) => a.verticalStepsFromCenter - b.verticalStepsFromCenter
  );

  // Calculate spacings
  const horizontalSpacings = getSpacings(
    sortedH.map((p) => p.horizontalStepsFrom50)
  );
  const verticalSpacings = getSpacings(
    sortedV.map((p) => p.verticalStepsFromCenter)
  );

  // Calculate average spacings
  const avgHorizontalSpacing = average(horizontalSpacings);
  const avgVerticalSpacing = average(verticalSpacings);

  // Calculate variance to determine uniformity
  const horizontalVariance = variance(horizontalSpacings, avgHorizontalSpacing);
  const verticalVariance = variance(verticalSpacings, avgVerticalSpacing);

  // Determine if current arrangement is more horizontal or vertical
  const horizontalSpread = Math.abs(
    sortedH[sortedH.length - 1].horizontalStepsFrom50 -
      sortedH[0].horizontalStepsFrom50
  );
  const verticalSpread = Math.abs(
    sortedV[sortedV.length - 1].verticalStepsFromCenter -
      sortedV[0].verticalStepsFromCenter
  );

  // Choose direction based on spread and current uniformity
  const direction =
    horizontalSpread > verticalSpread ? "horizontal" : "vertical";
  const currentSpacing =
    direction === "horizontal" ? avgHorizontalSpacing : avgVerticalSpacing;
  const relevantVariance =
    direction === "horizontal" ? horizontalVariance : verticalVariance;

  return {
    direction,
    currentSpacing,
    isUniform: relevantVariance < 0.5, // Threshold for considering spacing uniform
  };
}

function getSpacings(coordinates) {
  const spacings = [];
  for (let i = 1; i < coordinates.length; i++) {
    spacings.push(Math.abs(coordinates[i] - coordinates[i - 1]));
  }
  return spacings;
}

function average(numbers) {
  return numbers.length === 0
    ? 0
    : numbers.reduce((a, b) => a + b, 0) / numbers.length;
}

function variance(numbers, mean) {
  if (numbers.length === 0) return 0;
  return (
    numbers.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) /
    numbers.length
  );
}
