import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../services/firebase"; // Assuming you have this configured already
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import {
  ArrowPathIcon,
  ClockIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  GlobeAmericasIcon,
  LockClosedIcon,
  UserGroupIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { Clock } from "three";
import WaitlistForm from "../components/waitlist";
import ChangeLog from "../components/changelog.tsx";
import Footer from "../components/footer";

function MarketingApp() {
  const [animationClass, setAnimationClass] = useState("animate-fadeIn");
  const words = ["Design", "Collaboration", "Sharing", "Flo"];
  const [currentWord, setCurrentWord] = useState("Design");

  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // First, fade out the current word
      setAnimationClass("animate-fadeOut");

      // After fadeOut completes (500ms), change the word and fade in
      setTimeout(() => {
        setCurrentWord((prevWord) => {
          const currentIndex = words.indexOf(prevWord);
          const nextIndex = (currentIndex + 1) % words.length;
          return words[nextIndex];
        });

        // Apply fade-in after word change
        setAnimationClass("animate-fadeIn");
      }, 500); // Wait for fadeOut to complete before updating the word
    }, 3000); // Change every 3 seconds (with time for fade-out + fade-in)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "waitlistEmails"), {
        email,
        timestamp: serverTimestamp(),
      });
      setSuccessMessage("Thank you for joining the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error adding email to waitlist: ", error);
      alert("There was an issue adding you to the waitlist. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const features = [
    {
      name: "Customizable Field View",
      description:
        "  High school, college, or NFL fields—DrillFlo adapts to your needs.",
      icon: ViewfinderCircleIcon,
    },
    {
      name: "Easy Collaboration",
      description:
        "Collaborate with design teams to design a better end product.",
      icon: GlobeAmericasIcon,
    },
    {
      name: "Real-Time Feedback",
      description:
        " See changes in real-time as you tweak formations, with instant feedback for seamless adjustments.",
      icon: ClockIcon,
    },
    {
      name: "Easy Performer Placement",
      description:
        "   Drag and drop performers exactly where you need them, from simple lines to complex arcs and spirals.",
      icon: UserGroupIcon,
    },
  ];
  const softwareSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: "DrillFlo",
    description:
      "DrillFlo is a cloud-based drill design app for marching band professionals and hobbyists.",
    applicationCategory: "EducationalApplication",
    operatingSystem: "Web",
    url: "https://drillflo.com",
    image: "https://drillflo.com/logo.png",
    offers: {
      "@type": "Offer",
      price: "0.00",
      priceCurrency: "USD",
    },
    softwareVersion: "Beta",
    author: {
      "@type": "Organization",
      name: "DrillFlo Inc.",
    },
    isAccessibleForFree: "true",
    datePublished: "2024-12-01",
  };
  return (
    <div className=" ">
      <Helmet>
        <title>DrillFlo ✨ Marching Band Drill Design App</title>
        <meta
          name="description"
          content="DrillFlo is the next-generation freemium app for marching band drill designers, enabling modern creators to craft complex, dynamic drill design seamlessly from the cloud."
        />
        <meta
          name="keywords"
          content="marching band, drill design, drill writing, marching arts, cloud-based drill app, DrillFlo, free, professional, indoor guard, indoor percussion, drum corps, drum and bugle corps, "
        />
        <meta name="author" content="DrillFlo LLC" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="DrillFlo ✨ Marching Band Drill Design App"
        />
        <meta
          property="og:description"
          content="Craft complex, dynamic marching band formations with DrillFlo, the modern cloud-based app for drill designers."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.drillflo.com" />
        <meta
          property="og:image"
          content="https://www.drillflo.com/og-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="DrillFlo ✨ Marching Band Drill Design App"
        />
        <meta
          name="twitter:description"
          content="The next-gen app for marching band drill designers—bring your ideas to life with DrillFlo's cloud-based platform."
        />
        <meta
          name="twitter:image"
          content="https://www.drillflo.com/twitter-image.png"
        />
        <script type="application/ld+json">
          {JSON.stringify(softwareSchema)}
        </script>
      </Helmet>

      <header class="bg-white">
        <div class="mx-auto max-w-screen-xl px-0 sm:px-6 lg:px-0">
          <div class="flex h-16 items-center justify-between">
            <div class="md:flex md:items-center md:gap-12">
              <a class=" text-teal-600 flex flex-row" href="#">
                <span class="sr-only">Home</span>
                {/* <h1 className=" font-black tracking-tighter text-4xl bg-gradient-to-r from-blue-500  to-purple-600 bg-clip-text text-transparent">
                  DrillFlo
                </h1> */}
                <img src="/logo.png" className="w-52" />
                <span className="whitespace-nowrap font-bold rounded-full border-2 border-purple-500 px-2.5  text-sm text-purple-700 h-6 m-auto">
                  BETA
                </span>
              </a>
            </div>

            {/* <div class="hidden md:block">
              <nav aria-label="Global">
                <ul class="flex items-center gap-6 text-sm">
                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      About{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      Careers{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      History{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      Services{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      Projects{" "}
                    </a>
                  </li>

                  <li>
                    <a
                      class="text-gray-500 transition hover:text-gray-500/75"
                      href="#"
                    >
                      {" "}
                      Blog{" "}
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}

            {/* <div class="flex items-center gap-4">
              <div class="sm:flex sm:gap-4">
                <a
                  class="rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white shadow"
                  href="#"
                >
                  Login
                </a>

                <div class="hidden sm:flex">
                  <a
                    class="rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600"
                    href="#"
                  >
                    Register
                  </a>
                </div>
              </div>

              <div class="block md:hidden">
                <button class="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      <section className="bg-gray-100 text-gray-900 shadow-md py-60">
        <div className="mx-auto max-w-screen-xl px-4 py-40 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl text-gray-500 font-bold animate-slidein opacity-0 [--slidein-delay:300ms]">
              Coming Soon
            </h2>

            <h1 className=" animate-slidein opacity-0 [--slidein-delay:500ms] bg-gradient-to-r  from-blue-500 to-purple-600 bg-clip-text text-transparent font-extrabold text-3xl md:text-6xl transition-all ease-in">
              Next-Gen Marching Band
              <br /> Drill Design App.
            </h1>

            <p className="mx-auto mt-12 max-w-xl sm:text-xl/relaxed text-gray-700 animate-slidein opacity-0 [--slidein-delay:500ms]">
              Join the future of marching band drill design. Streamlined tools
              that make every step easier and more connected.
            </p>
            <img
              src="/assets/images/MacbookPreview2.png"
              className="rounded w-[100%] m-auto my-10 animate-slidein opacity-0 [--slidein-delay:600ms] "
              alt=""
            />
            <WaitlistForm />

            {/* <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className="block w-full shadow-lg rounded-full border border-purple-600 bg-purple-600 px-12 py-3 text-sm font-medium text-white hover:text-black hover:bg-transparent  focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                href="/register"
              >
                Get Started
              </a>

              <a
                className="block w-full shadow-lg rounded-full border border-purple-600 px-12 py-3 text-sm font-medium hover:text-white hover:bg-purple-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="/login"
              >
                Log In
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 md:items-center md:gap-8">
            <div className="md:col-span-3">
              <img
                src="/assets/images/DrillFlo-Demo-1.png"
                className="rounded shadow-lg"
                alt=""
              />
            </div>

            <div className="md:col-span-1">
              <div className="max-w-lg md:max-w-none">
                <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
                  Bring Your Production to Life
                </h2>

                <p className="mt-4 text-gray-700">
                  Design with ease using DrillFlo — the only web-based tool for
                  drill design. Collaborate, innovate, and simplify your process
                  like never before.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base/7 font-semibold text-purple-600">
                Elevate Your Designs. Empower Your Vision.
              </h2>
              <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
                The Essential Tool for Modern Drill Designers.
              </p>
              <p className="mt-6 text-lg/8 text-gray-600">
                As a drill designer, every move, count, and pathway starts with
                your vision. DrillFlo is being crafted with you in
                mind—intuitive, powerful, and designed to help you turn your
                ideas into unforgettable formations.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base/7 font-semibold text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-600">
                        <feature.icon
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base/7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="mx-auto max-w-screen-xl  py-8 sm:px-6 lg:px-0">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:items-center md:gap-8">
            <div>
              <div className="max-w-lg md:max-w-none">
                <h2 className="text-2xl font-semibold text-gray-900 sm:text-3xl">
                  On the Move.
                </h2>

                <p className="mt-4 text-gray-700">
                  DrillFlo is a browser based Marching Band Drill Design App,
                  giving you the flexibility to collaborate and work on your
                  production from anywhere.
                </p>
              </div>
            </div>

            <div>
              <img
                src="/assets/images/MacbookPreview.png"
                className="rounded"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        {" "}
        {/* <section class="py-24 ">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="mb-10 lg:mb-16 flex justify-center items-center flex-col gap-x-0 gap-y-6 lg:gap-y-0 lg:flex-row lg:justify-between max-md:max-w-lg max-md:mx-auto">
              <div class="relative w-full text-center lg:text-left lg:w-2/4">
                <h2 class="text-4xl font-bold text-gray-900 leading-[3.25rem] lg:mb-6 mx-auto max-w-max lg:max-w-md lg:mx-0">
                  Powerful Features at Your Fingertips
                </h2>
              </div>
            </div>
            <div class="flex justify-center items-center  gap-x-5 gap-y-8 lg:gap-y-0 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between lg:gap-x-8">
              <div class="group relative w-full bg-gray-100 rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 md:h-64 xl:p-7 xl:w-1/4 hover:bg-purple-600">
                <div class="bg-white rounded-full flex justify-center items-center mb-5 w-14 h-14 ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.7222 11.6667V7.22225C24.7222 5.99495 23.7273 5 22.5 5H4.72222C3.49492 5 2.5 5.99492 2.5 7.22222V22.7778C2.5 24.0051 3.49492 25 4.72222 25H22.5C23.7273 25 24.7222 24.005 24.7222 22.7777V17.7778M20.8333 17.7778H25.2778C26.5051 17.7778 27.5 16.7829 27.5 15.5556V13.8889C27.5 12.6616 26.5051 11.6667 25.2778 11.6667H20.8333C19.606 11.6667 18.6111 12.6616 18.6111 13.8889V15.5556C18.6111 16.7829 19.606 17.7778 20.8333 17.7778Z"
                      stroke="#4F46E5"
                      stroke-width="2"
                    ></path>
                  </svg>
                </div>
                <h4 class="text-xl font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">
                  Customizable Field View
                </h4>
                <p class="text-sm font-normal text-gray-500 transition-all duration-500 leading-5 group-hover:text-white">
                  High school, college, or NFL fields—DrillFlo adapts to your
                  needs.
                </p>
              </div>
              <div class="group relative w-full bg-gray-100 rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 md:h-64 xl:p-7 xl:w-1/4 hover:bg-purple-600">
                <div class="bg-white rounded-full flex justify-center items-center mb-5 w-14 h-14 ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.375 15.8571C16.1009 15.8571 17.5 14.458 17.5 12.7321C17.5 11.0062 16.1009 9.6071 14.375 9.6071C12.6491 9.6071 11.25 11.0062 11.25 12.7321C11.25 14.458 12.6491 15.8571 14.375 15.8571ZM14.375 15.8571V20.8571M3.75 13.2264V15.2343C3.75 17.6868 3.75 18.9131 4.27747 19.9685C4.80493 21.0239 5.78567 21.76 7.74715 23.2322L8.57248 23.8516C11.4626 26.0208 12.9077 27.1054 14.5753 27.1054C16.243 27.1054 17.688 26.0208 20.5782 23.8516L21.4035 23.2322C23.365 21.76 24.3457 21.0239 24.8732 19.9685C25.4006 18.9131 25.4006 17.6868 25.4006 15.2343V13.2264C25.4006 9.95932 25.4006 8.32576 24.546 7.05852C23.6913 5.79128 22.1768 5.17918 19.1477 3.95499L18.3223 3.62144C16.4724 2.87381 15.5475 2.5 14.5753 2.5C13.6032 2.5 12.6782 2.87381 10.8283 3.62144L10.003 3.95499C6.97389 5.17919 5.45934 5.79128 4.60467 7.05852C3.75 8.32576 3.75 9.95932 3.75 13.2264Z"
                      stroke="#4F46E5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <h4 class="text-xl font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">
                  Real-Time Feedback
                </h4>
                <p class="text-sm font-normal text-gray-500 transition-all duration-500 leading-5 group-hover:text-white">
                  See changes in real-time as you tweak formations, with instant
                  feedback for seamless adjustments.
                </p>
              </div>
              <div class="group relative w-full bg-gray-100 rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 md:h-64 xl:p-7 xl:w-1/4 hover:bg-purple-600">
                <div class="bg-white rounded-full flex justify-center items-center mb-5 w-14 h-14 ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0067 10V15.6652C15.0067 16.0358 15.1712 16.3873 15.4556 16.6248L18.75 19.375M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                      stroke="#4F46E5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <h4 class="text-xl font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">
                  Easy Performer Placement
                </h4>
                <p class="text-sm font-normal text-gray-500 transition-all duration-500 leading-5 group-hover:text-white">
                  Drag and drop performers exactly where you need them, from
                  simple lines to complex arcs and spirals.
                </p>
              </div>
              <div class="group relative w-full bg-gray-100 rounded-2xl p-4 transition-all duration-500 max-md:max-w-md max-md:mx-auto md:w-2/5 md:h-64 xl:p-7 xl:w-1/4 hover:bg-purple-600">
                <div class="bg-white rounded-full flex justify-center items-center mb-5 w-14 h-14 ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
                      stroke="#4F46E5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                <h4 class="text-xl font-semibold text-gray-900 mb-3 capitalize transition-all duration-500 group-hover:text-white">
                  Easy Collaboration
                </h4>
                <p class="text-sm font-normal text-gray-500 transition-all duration-500 leading-5 group-hover:text-white">
                  Collaborate with design teams to design a better end product.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </section>
      <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-[30%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[70deg] shadow-xl shadow-purple-600/50 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[90%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[80%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[70%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[60%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[50%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/60 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[40%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[30%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="absolute inset-y-0 right-[20%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

        <div className="absolute inset-y-0 right-[10%] -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] shadow-xl shadow-purple-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <figure className="mt-10">
            <blockquote className="text-center text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
              <p>
                We’re dedicated to supporting drill designers at every level.
                DrillFlo is being built for the community—get ready to design,
                share, and inspire.
              </p>
            </blockquote>
          </figure>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default MarketingApp;
