import React, { useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../ui/Button";

import { toast } from "react-hot-toast";

export function EmailForm() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (message && email) {
      const discordMessage = {
        content: `New Website Message from ` + email,

        embeds: [
          {
            title: email + " Submitted a Support Message ",
            description: message,
          },
        ],
      };

      fetch(
        "https://discord.com/api/webhooks/1315517242229653596/YA1Sm1NdoUHfSe1aLCxzpUGPiRr3brTnj3QQUDZIdRTgl97MOhwq-gfzzh3ABu0Fgpo4",
        {
          method: "POST",
          body: JSON.stringify(discordMessage),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        setEmail("");
        setMessage("");
        toast.success("Message Sent! We will get back to you soon");
      });
    } else if (!email) {
      toast.error("Please fill in your email address");
    } else if (!message) {
      toast.error("Please add a message");
    } else {
      toast.error("Something's missing here");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      <div>
        <label
          htmlFor="message"
          className="block text-sm font-medium text-gray-700"
        >
          Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          required
        />
      </div>

      {/* <ReCAPTCHA
        sitekey="YOUR_RECAPTCHA_SITE_KEY"
        onChange={(value) => setCaptchaValue(value)}
      /> */}

      <button
        type="submit"
        // disabled={!captchaValue}
        className="w-full inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
      >
        Send Message
      </button>
    </form>
  );
}
