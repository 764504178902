import { jsPDF } from "jspdf";
import Performer from "drillcore/lib/Performer";
import React, { useState } from "react";
import { toast } from "react-hot-toast";

const CoordinateModal = ({ drill, coordinateSheetData, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Convert coordinateSheetData to an array of performers
  const coordinateArray = Object.entries(coordinateSheetData); // [[performerId, data], ...]
  const totalPages = coordinateArray.length;

  const performers = drill.performers;
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Get the current performer's data
  const [currentPerformerId, currentPerformerData] =
    coordinateArray[currentPage - 1] || [];

  // Function to export the current performer sheet to a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();

    const performerName =
      performers.find(
        (performer) => performer.id === currentPerformerData?.performerId
      )?._name || "Unknown Performer";

    // Add the title
    doc.setFontSize(14);
    doc.text(`Coordinate Sheet - ${performerName}`, 10, 10);

    // Set table header
    const startY = 20;
    const margin = 5;
    const headerHeight = 10;
    let yOffset = startY + headerHeight;

    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.setFillColor(220, 220, 220); // Light gray color for header
    doc.rect(margin, yOffset, 190, headerHeight, "F"); // Draw header background
    doc.text("Page", margin + 5, yOffset + 6);
    doc.text("Coordinate", margin + 60, yOffset + 6);

    yOffset += headerHeight;

    // Add coordinates data with alternating row colors
    const rowHeight = 10;
    currentPerformerData.coordinates.forEach((pageData, index) => {
      const isEvenRow = index % 2 === 0;
      const rowY = yOffset + index * rowHeight;

      // Set alternating row colors
      doc.setFillColor(isEvenRow ? 240 : 255); // Light gray for even rows, white for odd rows
      doc.rect(margin, rowY, 190, rowHeight, "F");

      doc.setTextColor(0);
      doc.text(`${pageData.page}`, margin + 5, rowY + 6);
      doc.text(`${pageData.statement}`, margin + 60, rowY + 6);

      // Check if we need to add a new page
      if (rowY + rowHeight > 270) {
        doc.addPage();
        yOffset = startY;
        // Redraw the header for the new page
        doc.setFillColor(220, 220, 220);
        doc.rect(margin, yOffset, 190, headerHeight, "F");
        doc.text("Page", margin + 5, yOffset + 6);
        doc.text("Coordinates", margin + 60, yOffset + 6);
        yOffset += headerHeight;
      }
    });

    doc.setFontSize(8);
    doc.text(`Created with DrillFlo`, 180, 10);

    // Save the PDF
    doc.save(`${performerName}_coordinate_sheet.pdf`);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg w-full min-w-[60%]">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full ">
              <button
                onClick={onClose}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Close
              </button>
              <div className="sm:flex sm:items-start mt-4">
                <h3 className="text-xl font-bold">Coordinate Sheets</h3>
              </div>

              <div className="mt-4 bg-gray-100 p-2 shadow-md">
                <h3 className="text-xl font-bold">
                  Performer:{" "}
                  {performers.find(
                    (performer) =>
                      performer.id === currentPerformerData?.performerId
                  )?._name || "Unknown"}
                </h3>

                {currentPerformerData ? (
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Page</th>
                        <th className="px-4 py-2">Coordinates</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPerformerData.coordinates.map(
                        (pageData, index) => (
                          <tr key={index}>
                            <td className="px-4 py-2 font-medium text-gray-900">
                              {pageData.page}
                            </td>
                            <td className="px-4 py-2 text-gray-700 text-sm">
                              {currentPerformerData.statement
                                ? JSON.stringify(
                                    JSON.parse(pageData.statement),
                                    null,
                                    2
                                  )
                                : pageData.statement}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-gray-700">
                    No data available for this performer.
                  </p>
                )}

                <div className="flex justify-between px-4 py-2">
                  <button
                    onClick={handlePreviousPage}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50 flex-1"
                    disabled={currentPage === 1}
                  >
                    Previous Performer
                  </button>
                  <span className="text-sm text-gray-700 m-auto text-center flex-1">
                    Performer {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={handleNextPage}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50 flex-1"
                    disabled={currentPage === totalPages}
                  >
                    Next Performer
                  </button>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={exportToPDF}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-600 sm:mt-0 sm:w-auto"
                  >
                    Export to PDF
                  </button>
                  <button
                    onClick={onClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoordinateModal;
