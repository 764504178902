import MarketingNavbar from "../components/marketingNav";
import { onAuthStateChanged } from "firebase/auth";
import { db, functions, getUserPlanData } from "../services/firebase";
import { auth } from "../services/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  onSnapshot,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { async } from "@firebase/util";
import StripePricingTable from "../components/pricingTable";
import LoadingSpinner from "../components/loadingSpinner";

export default function PricingPage() {
  const [userData, setUserData] = useState(null); // Store user data
  const [loading, setLoading] = useState(false); // Store user data
  const [userRole, setUserRole] = useState(null); // Store user data
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData();
      } else {
        alert("No user found, please log in");
        navigate("/login"); // Or redirect to login
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        const userData = { id: userDoc.id, ...userDoc.data() };
        setUserData(userData);
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    getUserPlanData(auth.currentUser.uid).then((userPlanData) => {
      console.log("USER PLAN", userPlanData);
    });
    setLoading(false);
  };

  const subscribe = async (planId) => {
    if (!userData) {
      alert("Please wait for user data to load");
      return;
    }

    try {
      console.log("Starting subscription process for user:", userData.id);

      // Create the checkout session document
      const checkoutSessionRef = collection(
        db,
        "users",
        userData.uid,
        "checkout_sessions"
      );

      const sessionData = {
        mode: "subscription",
        success_url: window.location.origin + "/",
        cancel_url: window.location.origin + "/pricing",
        price: planId, // Use the passed planId for the price
        line_items: [
          {
            price: planId, // Use the passed planId for the price
            quantity: 1,
          },
        ],
        collect_shipping_address: true,

        metadata: {
          userId: userData.uid,
        },
      };

      console.log("Creating checkout session with data:", sessionData);

      const docRef = await addDoc(checkoutSessionRef, sessionData);
      console.log("Checkout session document created:", docRef.id);

      // Listen for updates to the checkout session
      const unsubscribe = onSnapshot(
        docRef,
        (snap) => {
          const data = snap.data();
          console.log("Checkout session updated data:", data);

          if (data?.error) {
            alert(`An error occurred: ${data.error.message}`);
            console.error("Stripe error:", data.error);
            unsubscribe();
          }

          if (data?.url) {
            console.log("Redirecting to Stripe URL:", data.url);
            window.location.assign(data.url);
            unsubscribe();
          }
        },
        (error) => {
          console.error("Error listening to checkout session:", error);
        }
      );
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("Failed to start checkout process. Please try again.");
    }
  };

  const getPortalLink = async (event) => {
    event.preventDefault();

    try {
      // Show loading state
      setLoading(true);

      const createPortalLink = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      const { data } = await createPortalLink({
        returnUrl: window.location.origin,
      });

      if (!data?.url) {
        throw new Error("No portal URL received from server");
      }

      // Redirect to the portal
      window.location.assign(data.url);
    } catch (error) {
      console.error("Portal link error:", error);
      // Show user-friendly error message
      alert(
        "Unable to access the billing portal. Please try again or contact support if the problem persists."
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <MarketingNavbar />

      <div class="relative isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
          aria-hidden="true"
        >
          <div
            class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#2a0fc5] to-[#ac109c] opacity-30"
            // style={{clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)}}
          ></div>
        </div>
        {/* <button onClick={getPortalLink}>PORTAL</button> */}

        <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <h2 class="text-base font-semibold leading-7 text-indigo-600">
            Pricing
          </h2>
          <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            The right price for you, whoever you are
          </p>
        </div>
        <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Qui iusto aut est earum eos quae. Eligendi est at nam aliquid ad quo
          reprehenderit in aliquid fugiat dolorum voluptatibus.
        </p>
        <div class="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8">
            <div class="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm bg-white">
              <div class="p-6 sm:px-8">
                <h2 class="text-lg font-medium text-gray-900">
                  Free
                  <span class="sr-only">Plan</span>
                </h2>

                <p class="mt-2 text-gray-700">
                  Design and bring your ideas to life, no cost. Great for
                  beginners and quick needs.
                </p>

                <p class="mt-2 sm:mt-4">
                  <strong class="text-3xl font-bold text-gray-900 sm:text-4xl">
                    {" "}
                    $0{" "}
                  </strong>

                  <span class="text-sm font-medium text-gray-700">/month</span>
                </p>

                <button
                  class="mt-4 block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
                  onClick={() => subscribe("price_1QNNwcELrUWyxuVLbefRous5")}
                >
                  Get Started
                </button>
              </div>

              <div class="p-6 sm:px-8">
                <p class="text-lg font-medium text-gray-900 sm:text-xl">
                  What's included:
                </p>

                <ul class="mt-2 space-y-2 sm:mt-4">
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 5 Drill Documents </span>
                  </li>
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 50 Performer Max </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 2GB of storage </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Email support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span class="text-gray-700"> Help center access </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span class="text-gray-700"> Phone support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span class="text-gray-700"> Community access </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm  bg-white">
              <div class="p-6 sm:px-8">
                <h2 class="text-lg font-medium text-gray-900">
                  Standard
                  <span class="sr-only">Plan</span>
                </h2>

                <p class="mt-2 text-gray-700">
                  Unlock premium access and larger storage amounts
                </p>

                <p class="mt-2 sm:mt-4">
                  <strong class="text-3xl font-bold text-gray-900 sm:text-4xl">
                    {" "}
                    $6.99{" "}
                  </strong>

                  <span class="text-sm font-medium text-gray-700">/month</span>
                </p>

                <button
                  class="mt-4 block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
                  onClick={() => subscribe("price_1QGTdRELrUWyxuVLYm3H9O6B")}
                >
                  Get Started
                </button>
              </div>

              <div class="p-6 sm:px-8">
                <p class="text-lg font-medium text-gray-900 sm:text-xl">
                  What's included:
                </p>

                <ul class="mt-2 space-y-2 sm:mt-4">
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 50 Drill Documents </span>
                  </li>
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 150 Performer Max </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 5GB of storage </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Email support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Help center access </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span class="text-gray-700"> Phone support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>

                    <span class="text-gray-700"> Community access </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm bg-white">
              <div class="p-6 sm:px-8">
                <h2 class="text-lg font-medium text-gray-900">
                  Professional
                  <span class="sr-only">Plan</span>
                </h2>

                <p class="mt-2 text-gray-700">
                  Transform your workflow and scale your drill design business.
                </p>

                <p class="mt-2 sm:mt-4">
                  <strong class="text-3xl font-bold text-gray-900 sm:text-4xl">
                    {" "}
                    $14.99{" "}
                  </strong>

                  <span class="text-sm font-medium text-gray-700">/month</span>
                </p>

                <button
                  class="mt-4 block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-center text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 sm:mt-6"
                  onClick={() => subscribe("price_1QGTdqELrUWyxuVLi8LXvbv0")}
                >
                  Get Started
                </button>
              </div>

              <div class="p-6 sm:px-8">
                <p class="text-lg font-medium text-gray-900 sm:text-xl">
                  What's included:
                </p>

                <ul class="mt-2 space-y-2 sm:mt-4">
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700">
                      {" "}
                      Unlimited Drill Documents{" "}
                    </span>
                  </li>
                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Unlimited Performer Max </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> 20GB of storage </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Email support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Help center access </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Phone support </span>
                  </li>

                  <li class="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-5 text-indigo-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>

                    <span class="text-gray-700"> Community access </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
