import { AudioLines, Columns4, Grip, Users } from "lucide-react";

const LeftNav = ({
  setShowAudioTrackModal,
  setShowFieldSettingsModal,
  showAudioTrackModal,
  showFeildSettingsModal,
  setShowPerformerLabelModal,
  showPerformerLabelModal,
  showCoordinateSheetModal,
  setShowCoordinateSheetModal,
}) => (
  <div
    id="default-sidebar"
    class="z-40 w-40 h-[50%] transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar"
  >
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 rounded-md border-r border-b border-t shadow-lg">
      <ul class="space-y-2 font-medium">
        <li>
          <button
            onClick={() => setShowFieldSettingsModal(!showFeildSettingsModal)}
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group"
          >
            <Columns4 className=" w-4 h-4" />

            <span class="ms-3">Field </span>
          </button>
        </li>
        <li>
          <button
            onClick={() => setShowAudioTrackModal(!showAudioTrackModal)}
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group audio-modal-open-button"
          >
            <AudioLines className=" w-4 h-4" />

            <span class="ms-3">Audio</span>
          </button>
        </li>
        {/* <li>
          <a
            href="#"
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group"
          >
            <svg
              class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 18"
            >
              <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
            <span class="ms-3">Elements</span>
          </a>
        </li> */}

        {/* <li>
          <a
            href="#"
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100 group"
          >
            <svg
              class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
            </svg>
            <span class="ms-3">Text</span>
          </a>
        </li> */}

        <li>
          <button
            onClick={() => setShowPerformerLabelModal(!showPerformerLabelModal)}
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group performer-modal-open-button"
          >
            <Users className=" w-4 h-4" />

            <span class="ms-3">Performers</span>
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              setShowCoordinateSheetModal(!showCoordinateSheetModal)
            }
            class="flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group"
          >
            <Grip className=" w-4 h-4" />
            <span class="ms-3">Dot Sheets </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
);

export default LeftNav;
