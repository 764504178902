/**
 * @file Drill.ts
 * @module Drill
 * @description This file contains the beginnings of the Drill structure, which
 * is used to represent marching drill agnostic to the underlying rendering context.
 *
 * The Drill structure will allow for the creation of drill formations, movements,
 * and other related functionalities. It will serve as the foundation for the
 * drill rendering and manipulation. Sub-stuctures will be created to represent
 * individual pages, "dots", and other components of the drill.
 * @author Marcus Dubreuil
 */
import Page from "./Page";
import Performer from "./Performer";
export var HashType;
(function (HashType) {
    HashType["HIGH_SCHOOL"] = "HIGH_SCHOOL";
    HashType["COLLEGE"] = "COLLEGE";
    HashType["PROFESSIONAL"] = "PROFESSIONAL";
})(HashType || (HashType = {}));
export default class Drill {
    engine;
    _title;
    _pages;
    _performers = [];
    _selectedPageIndex = 0;
    disableDraw = false;
    constructor(engine, title = "My Drill", pages = [], performers = []) {
        this.engine = engine;
        this._title = title;
        this._pages = pages;
        this._performers = performers;
        if (this._pages.length === 0) {
            this._pages.push(new Page(this));
        }
        this.draw();
    }
    /**
     * Get the currently selected page. There is always at least one page.
     * @returns {Page} The currently selected page.
     */
    get selectedPage() {
        return this._pages[this._selectedPageIndex];
    }
    /**
     * Get the index of the currently selected page.
     * @returns {number} The index of the currently selected page.
     */
    get selectedPageIndex() {
        return this._selectedPageIndex;
    }
    /**
     * Gets the full list of pages.
     * @returns {Page[]} The list of pages.
     */
    get pages() {
        return this._pages;
    }
    /**
     * Gets the full list of performers.
     * @returns {Performer[]} The list of performers.
     */
    get performers() {
        return this._performers;
    }
    /**
     * Sets the list of performers.
     * @param performers The new list of performers.
     */
    set performers(performers) {
        this._performers = performers;
        this.draw();
    }
    /**
     * Get the title of the drill.
     * @returns {string} The title of the drill.
     */
    get title() {
        return this._title;
    }
    /**
     * Set the title of the drill.
     * @param title The new title of the drill.
     */
    set title(title) {
        this._title = title;
    }
    /**
     * Select a page by index. If the index is out of bounds, the selection will not change.
     * @param index The index of the page to select.
     */
    selectPage(index) {
        if (index >= 0 && index < this._pages.length) {
            this._selectedPageIndex = index;
        }
        this.draw();
    }
    /**
     * Add a new page to the drill.
     * @param index [optional] The index at which to insert the new page. If not provided, the page will be added to the end.
     */
    addPage(page, index) {
        let lastPage = this._pages.length > 0 ? this._pages[this._pages.length - 1] : undefined;
        page = page || new Page(this, lastPage);
        if (index !== undefined) {
            this._pages.splice(index, 0, page);
        }
        else {
            this._pages.push(page);
        }
        this.selectPage(index !== undefined ? index : this._pages.length - 1);
    }
    /**
     * Add a performer to the drill.
     * @param performer Performer to add
     * @param index [optional] The index at which to insert the performer. If not provided, the performer will be added to the end.
     * @param startingPosition [optional] The starting position for the performer. If not provided, a default position will be assigned.
     */
    addPerformer(performer, index, startingPosition) {
        if (index !== undefined) {
            this._performers.splice(index, 0, performer);
        }
        else {
            this._performers.push(performer);
        }
        // Set the performer position on all pages
        this._pages.forEach((page) => {
            page.setPerformerPosition(performer, startingPosition || page.getNextAvailableDefaultPosition());
        });
        this.draw();
    }
    /**
     * Draw the drill to the engine. This does not draw the drill to the canvas, just
     * prepares the engine to do so.
     */
    draw() {
        if (!this.disableDraw) {
            this.engine.drawDrill(this);
        }
    }
    /**
     * Serialize the page data for storage or transmission.
     * Includes pages, properties, and performers.
     * @returns {SerializedDrill} The serialized drill data.
     */
    serialize() {
        return {
            title: this._title,
            pages: this._pages.map((page) => page.serialize()),
            performers: this._performers.map((performer) => ({
                id: performer.id,
                name: performer.name,
                number: performer.number,
                symbol: performer.symbol,
            })),
            displayOptions: {
                hashType: this.engine.hashType,
                endZones: this.engine.endZones,
            },
        };
    }
    /**
     * Loads a serialized drill into a Drill instance.
     * @param serializedDrill The serialized drill data.
     * @param engine The engine instance to associate with the drill.
     */
    static load(serializedDrill, engine) {
        engine.hashType = serializedDrill.displayOptions.hashType;
        engine.endZones = serializedDrill.displayOptions.endZones;
        const drill = new Drill(engine, serializedDrill.title);
        drill.disableDraw = true; // Prevent drawing while loading
        drill.performers = serializedDrill.performers.map((performerData) => {
            return new Performer(drill, performerData.name, performerData.number, performerData.symbol, performerData.id);
        });
        const performerById = new Map(drill.performers.map((p) => [p.id, p]));
        serializedDrill.pages.forEach((pageData) => {
            const page = new Page(drill);
            pageData.performerPositions.forEach((positionData) => {
                const performer = performerById.get(positionData.performerId);
                if (performer) {
                    page.setPerformerPosition(performer, positionData.position);
                }
            });
            page.customMessage = pageData.customMessage || "";
            drill.addPage(page);
        });
        drill.disableDraw = false; // Re-enable drawing
        drill.draw(); // Draw the drill after loading
        return drill;
    }
}
