import logo from "./logo.svg";
import "./App.css";
import FieldEditor from "./components/fieldEditor";
import { Route, Routes } from "react-router-dom";
import Login from "./views/login";
import MyDesigns from "./views/my-design";
import RegisterPage from "./views/register";
import HomePage from "./views/home";
import MyAccount from "./views/my-account";
import PricingPage from "./views/pricing";
import toast, { Toaster } from "react-hot-toast";
import FieldEditor2 from "./components/fieldEditor2.tsx";
import AccessDenied from "./components/AccessDenied.tsx";
import ChangeLogPage from "./views/changeLog";
import GuideBookPage from "./views/guidebook";
import InvitationPage from "./views/invitationPage";
import { SupportWidget } from "./components/supportWidget/supportWidget.tsx";
import AboutPage from "./views/about";
import MobileDisabled from "./views/mobileDisabled";

function App() {
  return (
    <div className=" ">
      <Toaster />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/mobile-disabled" element={<MobileDisabled />} />
        <Route
          path="/access-denied"
          element={<AccessDenied message="You don't have Access" />}
        />
        <Route path="/my-designs" element={<MyDesigns />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/changelog" element={<ChangeLogPage />} />
        <Route path="/guidebook" element={<GuideBookPage />} />

        <Route path="/designs/:slug" element={<FieldEditor2 />} />
        <Route path="/invitation" element={<InvitationPage />} />
        {/* <Route path="/designs2/:slug" element={<FieldEditor2 />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
      </Routes>
      <SupportWidget />
    </div>
  );
}

export default App;
