/**
 * @file ExportEngine.ts
 * @module ExportEngine
 * @description The ExportEngine is responsible for exporting the drillcore data in 2D to
 * a PDF file or directly to a printer. It consists of both a preview and a final export function.
 * @author Marcus Dubreuil
 */
import Drill from "../Drill";
import Engine, { DEFAULT_DRILL_COLORS } from "./Engine";
import paper from "paper";
const EXPORT_VERSION = "v0.1.0 (Beta)";
export default class ExportEngine {
    previewCanvas;
    drill;
    drillEngine;
    drillBoundsRectangle;
    constructor(baseDrill, previewCanvas, colors = DEFAULT_DRILL_COLORS, endZones, hashType, fieldYardHeight) {
        this.previewCanvas = previewCanvas;
        // Ensure background is white, we ain't savages trying to waste ink
        colors = {
            ...colors,
            background: new paper.Color(1, 1, 1),
        };
        // Ensure canvas has no border
        this.previewCanvas.style.border = "none";
        this.drillEngine = new Engine(this.previewCanvas, colors, endZones, hashType, fieldYardHeight);
        // Copy over movement arrows
        this.drillEngine.movementArrows = baseDrill.engine.movementArrows;
        // Basically copy the drill, but with a different engine
        this.drill = new Drill(this.drillEngine, baseDrill.title, baseDrill.pages.slice(), baseDrill.performers.slice());
        this.drill.selectPage(0);
        this._createDrillBounds();
        this._draw();
    }
    _createDrillBounds() {
        // Set the bounds of the drill to be 95% of the canvas width
        this.drillBoundsRectangle = new paper.Rectangle(0, 0, this.previewCanvas.clientWidth * 0.95, this.previewCanvas.clientHeight);
        // Center the drill on the canvas
        this.drillBoundsRectangle.center = new paper.Point(this.previewCanvas.clientWidth / 2, this.previewCanvas.clientHeight / 2);
    }
    _draw() {
        const ctx = this.previewCanvas.getContext("2d");
        if (!ctx)
            return;
        this.drill.draw();
        this.drillEngine.drawToCanvas(this.drillBoundsRectangle);
        // Get the canvas paper scope to draw more stuff
        const canvasScope = this.drillEngine.rawCanvasPaperScope;
        const width = this.previewCanvas.clientWidth;
        const height = this.previewCanvas.clientHeight;
        const offsetX = width * 0.03;
        const offsetY = height * 0.1;
        const fontSize = width * 0.05;
        // Draw the title of the drill
        const titleText = new canvasScope.PointText(new canvasScope.Point(offsetX, offsetY));
        titleText.justification = "left";
        titleText.fillColor = new paper.Color(0, 0, 0);
        titleText.fontSize = fontSize * 0.4;
        titleText.fontWeight = 700;
        titleText.content = `Project - ${this.drill.title}`;
        // On the right side of the canvas, draw the set number
        const setNumberText = new canvasScope.PointText(new canvasScope.Point(width - offsetX, offsetY));
        setNumberText.justification = "right";
        setNumberText.fillColor = new paper.Color(0, 0, 0);
        setNumberText.fontSize = fontSize * 0.3;
        titleText.fontWeight = 600;
        setNumberText.content = `Set ${this.drill.selectedPageIndex + 1}`;
        // Draw Directors Viewpoint under drill
        const directorsViewpointLAbel = new canvasScope.PointText(new canvasScope.Point(width / 2, height - offsetY * 1.6));
        directorsViewpointLAbel.justification = "center";
        directorsViewpointLAbel.fillColor = new paper.Color(0, 0, 0);
        directorsViewpointLAbel.fontSize = fontSize * 0.2;
        directorsViewpointLAbel.fontWeight = 500;
        directorsViewpointLAbel.content = "Director Viewpoint";
        // Draw "drillflo.com" at the bottom right of the canvas
        const drillFloText = new canvasScope.PointText(new canvasScope.Point(width - offsetX, height - offsetY));
        drillFloText.justification = "right";
        drillFloText.fillColor = new paper.Color(0, 0, 0);
        drillFloText.fontSize = fontSize * 0.3;
        drillFloText.fontWeight = 700;
        drillFloText.content = "DrillFlo.com";
        // Draw the version number at the bottom right of the canvas,
        // below the "drillflo.com" text
        const versionText = new canvasScope.PointText(new canvasScope.Point(width - offsetX, height - offsetY * 0.5));
        versionText.justification = "right";
        versionText.fillColor = new paper.Color(0, 0, 0);
        versionText.fontSize = fontSize * 0.2;
        versionText.content = `${EXPORT_VERSION}`;
        // If there is a custom message, draw it at the bottom left of the canvas
        const customMessage = this.drill.pages[this.drill.selectedPageIndex].customMessage;
        console.log(customMessage);
        if (customMessage) {
            // Split the custom message into lines
            const lines = customMessage.split("\n");
            // Reverse the lines so we can draw them from the bottom up
            lines.reverse();
            let y = height - offsetY / 2;
            for (const line of lines) {
                const customMessageFontSize = fontSize * 0.45;
                // Measure the height of the custom message
                ctx.font = `${customMessageFontSize}px sans-serif`;
                const symbolMetrics = ctx.measureText(customMessage);
                const symbolHeight = symbolMetrics.actualBoundingBoxAscent;
                const customMessageText = new canvasScope.PointText(new canvasScope.Point(offsetX, y - symbolHeight));
                customMessageText.justification = "left";
                customMessageText.fillColor = new paper.Color(0, 0, 0);
                customMessageText.fontSize = customMessageFontSize;
                customMessageText.content = line;
                // If the width of the custom message is too long, we need to reduce the font size
                while (customMessageText.bounds.width > width * 0.7 - offsetX * 2 &&
                    customMessageText.fontSize > 1) {
                    customMessageText.fontSize -= 1;
                }
                // Move up the y position for the next line
                y -= symbolHeight * 1.5;
            }
        }
        canvasScope.view.update();
    }
    /**
     * Flips the drill to the previous page, only within the preview.
     * @returns {void}
     */
    previousPage() {
        if (this.drill.selectedPageIndex === 0) {
            return;
        }
        this.drill.selectPage(this.drill.selectedPageIndex - 1);
        this._draw();
    }
    /**
     * Flips the drill to the next page, only within the preview.
     * @returns
     */
    nextPage() {
        if (this.drill.selectedPageIndex === this.drill.pages.length - 1) {
            return;
        }
        this.drill.selectPage(this.drill.selectedPageIndex + 1);
        this._draw();
    }
    /**
     * Downloads the drill as a PDF file to the browser.
     */
    async print() {
        this._createDrillBounds();
        let selectedPageIndex = this.drill.selectedPageIndex;
        // Get each page as an SVG, because we like vector graphics!
        const svgs = [];
        for (let i = 0; i < this.drill.pages.length; i++) {
            this.drill.selectPage(i);
            this._draw();
            const canvasPaperScope = this.drillEngine.rawCanvasPaperScope;
            // Add a debug rectangle around the bounds of the canvas to show the
            // printable area
            // const debugRectangle = new canvasPaperScope.Path.Rectangle(
            //   this.drillBoundsRectangle
            // );
            // debugRectangle.strokeColor = new canvasPaperScope.Color(255, 0, 0);
            // debugRectangle.strokeWidth = 5;
            // Clear all the "data" properties of the Paper.js objects to ensure
            // we do not store any unnecessary data in the SVG and to stop
            // issues with circular references
            canvasPaperScope.project
                .getItems({
                recursive: true,
            })
                .forEach((item) => {
                item.data = {};
            });
            svgs.push(canvasPaperScope.project.exportSVG({ asString: true }));
        }
        // Open up a new window to create the PDF
        const htmlContent = `
        <!DOCTYPE html>
        <html>
        <head>
            <title>${this.drill.title}</title>
            <style>
                 @page {
                    size: landscape;
                }
                body {
                    margin: 0;
                    padding: 0;
                }
                .page {
                    display: flex;
                    justify-content: center; /* Horizontally center SVG */
                    align-items: center;   /* Vertically center SVG */
                    width: 100%;
                    height: 100vh; /* Full page height */
                    page-break-after: always; /* Ensure each SVG starts on a new page */
                }
                svg {
                    max-width: 95%; /* Scale to fit within the page */
                    max-height: 95%; /* Leave some padding */
                    display: block;
                }
            </style>
        </head>
        <body>
             ${svgs.map((svg) => `<div class="page">${svg}</div>`).join("\n")}
        </body>
        </html>
    `;
        const pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(htmlContent);
        pdfWindow.document.close();
        // Wait for the window to load
        await new Promise((resolve) => {
            pdfWindow.addEventListener("load", resolve);
        });
        // Print the window
        pdfWindow.print();
        // Reset the selected page index
        this.drill.selectPage(selectedPageIndex);
        // Reset the canvas
        this._createDrillBounds();
        this._draw();
    }
}
