import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db, logout } from "../services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { setUserId } from "firebase/analytics";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function MarketingNavbar() {
  const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // New state for mobile menu
  const [userData, setUserData] = useState(null); // Store user data

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        fetchUserData();
        setUser(user);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]); // Include `navigate` in dependencies
  const fetchUserData = async () => {
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        const userData = { id: userDoc.id, ...userDoc.data() };
        setUserData(userData);
      } else {
        // console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  return (
    <header className="bg-white">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <a className=" text-blue-600 flex flex-row" href="/">
              <span className="sr-only">Home</span>

              <img src="/logo.png" className="w-52" />
              <span className="whitespace-nowrap font-bold rounded-full border-2 border-purple-500 px-2.5  text-sm text-purple-700 h-6 m-auto">
                BETA
              </span>
            </a>
          </div>
          {/* <div className="flex w-4/5 m-auto flex-1">
            <div class="relative">
              <label for="Search" class="sr-only">
                {" "}
                Search{" "}
              </label>

              <input
                type="text"
                id="Search"
                placeholder="Search designs and documents"
                class="w-full rounded-md border-gray-200 py-2.5 pe-10 shadow-sm sm:text-sm"
              />

              <span class="absolute inset-y-0 end-0 grid w-10 place-content-center">
                <button type="button" class="text-gray-600 hover:text-gray-700">
                  <span class="sr-only">Search</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
              </span>
            </div>
          </div> */}
          <div className="md:flex md:items-center md:gap-12">
            <div className="hidden md:relative md:block">
              {isLoggedIn ? (
                <button
                  type="button"
                  onClick={() => setAvatarMenuOpen(!avatarMenuOpen)}
                  className="overflow-hidden rounded-full border border-gray-300 shadow-inner"
                >
                  <span className="sr-only">Toggle dashboard menu</span>

                  <img
                    src={`https://ui-avatars.com/api/?background=0000FF&color=fff&name=${userData?.firstName}+${userData?.lastName}`}
                    alt=""
                    className="size-10 object-cover"
                  />
                </button>
              ) : (
                <>Login</>
              )}

              {avatarMenuOpen && (
                <div className="absolute end-0 z-10 mt-0.5 w-56 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg">
                  <div className="p-2">
                    <Link
                      to="/my-account"
                      className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    >
                      My account
                    </Link>
                  </div>

                  <div className="p-2">
                    <button
                      type="submit"
                      className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 hover:bg-red-50"
                      onClick={() => logout(auth)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                        />
                      </svg>
                      Logout
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="block md:hidden">
              <button
                className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)} // Toggle mobile menu
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
