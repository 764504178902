import React, { useEffect, useRef, useState } from "react";
import { X, UserPlus, Mail, Edit2 } from "lucide-react";
import { db } from "../services/firebase";
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  addDoc,
  collection,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import {
  findUserByEmail,
  createPendingInvite,
} from "../services/userService.tsx";
import emailjs from "@emailjs/browser";
import { v4 as uuidv4 } from "uuid";

interface CollaboratorModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  currentCollaborators: Array<{
    email: string;
    role: "editor" | "viewer";
    uid?: string;
  }>;
  title: string;
  print: Function;
}

export default function CollaboratorModal({
  isOpen,
  onClose,
  documentId,
  currentCollaborators,
  print,
  title,
}: CollaboratorModalProps) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<"editor" | "viewer">("editor");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingCollaborator, setEditingCollaborator] = useState<string | null>(
    null
  );

  useEffect(() => emailjs.init(process.env.REACT_APP_EMAIL_API || ""), []);
  if (!isOpen) return null;

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Check if email is already a collaborator
      if (currentCollaborators.some((c) => c.email === email)) {
        toast.error("This user is already a collaborator");
        return;
      }

      // Find if user exists
      const existingUser = await findUserByEmail(email);
      const docRef = doc(db, "designs", documentId);

      if (existingUser) {
        // Add existing user as collaborator
        await updateDoc(docRef, {
          collaborators: arrayUnion({
            email,
            role,
            uid: existingUser.uid,
          }),
          collaboratorIds: arrayUnion({
            uid: existingUser.uid,
          }),
        });
        toast.success("Collaborator added successfully");
      } else {
        // Create pending invite for new user
        const inviteToken = uuidv4();
        const inviteUrl = `https://www.drillflo.com/invitation?token=${inviteToken}`;

        const inviteCreated = await createPendingInvite(
          documentId,
          email,
          role,
          inviteToken
        );

        if (inviteCreated) {
          // Send email invitation
          const inviteInfo = {
            documentId,
            email,
            role,
            title,
            inviteUrl,
          };
          const serviceId = "service_divjok6";
          const templateId = "template_hy6n0jz";
          try {
            await emailjs.send(serviceId, templateId, inviteInfo);
            toast.success("Invitation sent successfully");
          } catch (error) {
            console.log(error);
          }
        } else {
          throw new Error("Failed to create invite");
        }
      }

      setEmail("");
      setRole("editor");
    } catch (error) {
      toast.error("Failed to process invitation");
      console.error("Error processing invitation:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCollaboratorRole = async (
    email: string,
    newRole: "editor" | "viewer"
  ) => {
    try {
      const docRef = doc(db, "designs", documentId);
      const oldCollaborator = currentCollaborators.find(
        (c) => c.email === email
      );

      if (oldCollaborator) {
        await updateDoc(docRef, {
          collaborators: arrayRemove(oldCollaborator),
        });
        await updateDoc(docRef, {
          collaborators: arrayUnion({
            ...oldCollaborator,
            role: newRole,
          }),
        });
        toast.success("Role updated successfully");
        setEditingCollaborator(null);
      }
    } catch (error) {
      toast.error("Failed to update role");
      console.error("Error updating collaborator role:", error);
    }
  };

  const removeCollaborator = async (email: string, uid: string) => {
    try {
      const docRef = doc(db, "designs", documentId);
      const collaborator = currentCollaborators.find((c) => c.email === email);

      if (collaborator) {
        await updateDoc(docRef, {
          collaborators: arrayRemove(collaborator),
          collaboratorIds: arrayRemove({
            uid: uid,
          }),
        });
        toast.success("Collaborator removed");
      }
    } catch (error) {
      toast.error("Failed to remove collaborator");
      console.error("Error removing collaborator:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Manage Collaborators</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <form onSubmit={handleInvite} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="collaborator@example.com"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value as "editor" | "viewer")}
              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="editor">Editor</option>
              <option value="viewer">Viewer</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </span>
            ) : (
              <span className="flex items-center">
                <UserPlus className="h-5 w-5 mr-2" />
                Invite Collaborator
              </span>
            )}
          </button>
        </form>
        {currentCollaborators.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-3">
              Current Collaborators
            </h3>
            <div className="space-y-2">
              {currentCollaborators.map((collaborator) => (
                <div
                  key={collaborator.email}
                  className="flex justify-between items-center p-3 bg-gray-50 rounded-md"
                >
                  <div className="flex-grow">
                    <p className="text-sm font-medium text-gray-900">
                      {collaborator.email}
                    </p>
                    {editingCollaborator === collaborator.email ? (
                      <select
                        value={collaborator.role}
                        onChange={(e) =>
                          updateCollaboratorRole(
                            collaborator.email,
                            e.target.value as "editor" | "viewer"
                          )
                        }
                        className="mt-1 block w-full text-sm pl-2 pr-8 py-1 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        autoFocus
                        onBlur={() => setEditingCollaborator(null)}
                      >
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    ) : (
                      <div className="flex items-center gap-2">
                        <p className="text-sm text-gray-500 capitalize">
                          {collaborator.role}
                        </p>
                        <button
                          onClick={() =>
                            setEditingCollaborator(collaborator.email)
                          }
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <Edit2 className="h-3 w-3" />
                        </button>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      removeCollaborator(collaborator.email, collaborator.uid)
                    }
                    className="ml-4 text-red-600 hover:text-red-800"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
