import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import MarketingNavbar from "../components/marketingNav";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";
import MyDesigns from "./my-design";
import MarketingApp from "./landing-page";

export default function HomePage() {
  const [currentWord, setCurrentWord] = useState("Design");
  const [animationClass, setAnimationClass] = useState("animate-fadeIn");
  const words = ["Design", "Collaboration", "Sharing"];
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  useEffect(() => {
    const intervalId = setInterval(() => {
      // First, fade out the current word
      setAnimationClass("animate-fadeOut");

      // After fadeOut completes (500ms), change the word and fade in
      setTimeout(() => {
        setCurrentWord((prevWord) => {
          const currentIndex = words.indexOf(prevWord);
          const nextIndex = (currentIndex + 1) % words.length;
          return words[nextIndex];
        });

        // Apply fade-in after word change
        setAnimationClass("animate-fadeIn");
      }, 500); // Wait for fadeOut to complete before updating the word
    }, 3000); // Change every 3 seconds (with time for fade-out + fade-in)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  if (isLoggedIn) return <MyDesigns />;
  return (
    <>
      <Helmet>
        <title>DrillFlo ✨ The future of Drill Creation</title>
      </Helmet>
      {/* <MarketingNavbar /> */}
      <MarketingApp />
    </>
  );
}
