export default function convertToMarchingCoordinates(
  horizontalSteps,
  verticalSteps
) {
  const side = horizontalSteps < 0 ? "Side 1" : "Side 2";
  const stepsFrom50 = Math.abs(horizontalSteps);
  const yardsFrom50 = Math.floor(stepsFrom50 / 8);
  const yardLineBase =
    side === "Side 1" ? 50 - yardsFrom50 * 5 : 50 - yardsFrom50 * 5;

  // Steps to calculate position relative to the yard line
  const stepsOffset = stepsFrom50 % 8;
  const stepsToPrint =
    stepsOffset > 4 ? (8 - stepsOffset).toFixed(2) : stepsOffset.toFixed(2);

  const yardToPrint =
    stepsOffset > 4
      ? yardLineBase + (side === "Side 1" ? -5 : -5)
      : yardLineBase;

  const relativePosition =
    stepsOffset === 0
      ? `On ${yardToPrint}-yard line`
      : `${stepsToPrint} steps ${
          stepsOffset <= 4 ? "outside" : "inside"
        } ${yardToPrint}-yard line`;

  const yardLine =
    stepsOffset === 0 ? `On ${yardLineBase}-yard line` : relativePosition;

  // Vertical boundaries and calculations
  const verticalLabels = [
    { label: "back sideline", boundary: 40 },
    { label: "back hash", boundary: 8 },
    // { label: "center line", boundary: 0 },
    { label: "front hash", boundary: -8 },
    { label: "front sideline", boundary: -40 },
  ];

  let positionLabel = "";
  let direction = "on";
  let stepsFromLabel = 0;

  // Find the closest vertical boundary
  for (let i = 0; i < verticalLabels.length - 1; i++) {
    const currentBoundary = verticalLabels[i].boundary;
    const nextBoundary = verticalLabels[i + 1].boundary;

    // Check if the vertical position is between two boundaries
    if (verticalSteps <= currentBoundary && verticalSteps > nextBoundary) {
      const distanceToCurrent = Math.abs(verticalSteps - currentBoundary);
      const distanceToNext = Math.abs(verticalSteps - nextBoundary);

      // Choose the closer boundary
      if (distanceToCurrent <= distanceToNext) {
        stepsFromLabel = distanceToCurrent;
        positionLabel = verticalLabels[i].label;
        direction =
          verticalSteps === currentBoundary
            ? "on"
            : verticalSteps > currentBoundary
            ? "behind"
            : "in front of";
      } else {
        stepsFromLabel = distanceToNext;
        positionLabel = verticalLabels[i + 1].label;
        direction =
          verticalSteps === nextBoundary
            ? "on"
            : verticalSteps > nextBoundary
            ? "behind"
            : "in front of";
      }
      break;
    }
  }

  // Handle the edge cases (beyond the last boundary)
  if (!positionLabel) {
    const lastLabel =
      verticalSteps > verticalLabels[0].boundary
        ? verticalLabels[0] // Above the back sideline
        : verticalLabels[verticalLabels.length - 1]; // Below the front sideline;

    stepsFromLabel = Math.abs(verticalSteps - lastLabel.boundary);
    positionLabel = lastLabel.label;
    direction = verticalSteps > lastLabel.boundary ? "in front of" : "behind";
  }

  // Compile the final coordinates object
  const coordinates = {
    side,
    yardLine,
    stepsFromLabel,
    direction,
    positionLabel,
    statement: `${side}, ${yardLine}, ${stepsFromLabel.toFixed(
      2
    )} steps ${direction} the ${positionLabel}.`,
  };

  return coordinates;
}

// Example usage
const horizontalStepsFrom50 = -32;
const verticalStepsFromCenter = -10; // Adjust this to test various cases
const convertedPosition = convertToMarchingCoordinates(
  horizontalStepsFrom50,
  verticalStepsFromCenter
);
console.log(convertedPosition);
