import Performer from "drillcore/lib/Performer";
import React, { useState } from "react";
import { toast } from "react-hot-toast";

const PerformerModal = ({
  drill,
  setRedrawNonce,
  updateLabelAcrossPages,
  onClose,
}) => {
  const [selectedPerformers, setSelectedPerformers] = useState(
    drill?.performers || []
  );

  const [bulkPerformersCount, setBulkPerformersCount] = useState(1);
  const [bulkLabel, setBulkLabel] = useState("X");

  // Pagination Logic
  const performersPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(selectedPerformers.length / performersPerPage);

  const currentPerformers = selectedPerformers.slice(
    (currentPage - 1) * performersPerPage,
    currentPage * performersPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLabelChange = (index, e) => {
    const updatedPerformers = [...selectedPerformers];
    updatedPerformers[index].label = e.target.value;
    setSelectedPerformers(updatedPerformers);
  };

  const handleLabelSubmit = (e) => {
    e.preventDefault();
    selectedPerformers.forEach((performer, index) => {
      updateLabelAcrossPages(index, performer.label);
    });
    onClose();
  };

  const handleAddPerformers = async (numOfPerformers, letterLabel) => {
    if (!drill || !numOfPerformers || !letterLabel) return;
    if (numOfPerformers > 50)
      return toast.error("Too many performers. You can add up to 50 per label");
    drill.disableDraw = true;
    for (let i = 0; i < numOfPerformers; i++) {
      const performer = new Performer(
        drill,
        `${letterLabel}${i + 1}`,
        i + 1,
        letterLabel
      );
      drill.addPerformer(performer);
    }
    drill.disableDraw = false;
    toast.success("Performers added");
    setRedrawNonce((prevNonce) => prevNonce + 1);
    setSelectedPerformers(drill.performers);
  };

  return (
    <>
      <div
        className="relative z-50"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg w-full min-w-[60%]">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full ">
                <button
                  onClick={onClose}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Close
                </button>
                <div className="sm:flex sm:items-start mt-4">
                  <h3 className="text-xl font-bold">Performers</h3>
                </div>

                {/* Bulk Add Performers */}
                <div className="mt-4 bg-gray-100 p-2 shadow-md">
                  <h3 className="text-lg font-semibold my-2">Add Performers</h3>
                  <div className="flex flex-row gap-2">
                    <div className="flex-1">
                      <label
                        htmlFor="performerCount"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {" "}
                        Number of Performers
                      </label>
                      <input
                        type="number"
                        id="performerCount"
                        className="mt-1.5 h-10 w-full rounded-sm border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                        value={bulkPerformersCount}
                        onChange={(e) =>
                          setBulkPerformersCount(parseInt(e.target.value, 10))
                        }
                        min="1"
                        max={50}
                        maxLength="1"
                      />
                    </div>

                    <div className="flex-1">
                      <label
                        htmlFor="labelLetter"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {" "}
                        Performer Label
                      </label>

                      <select
                        name="labelLetter"
                        id="labelLetter"
                        value={bulkLabel}
                        onChange={(e) =>
                          setBulkLabel(e.target.value.toUpperCase())
                        }
                        className="mt-1.5 w-full rounded-sm border-gray-300 text-gray-700 sm:text-sm h-10"
                      >
                        <option disabled value="">
                          Please select
                        </option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                        <option value="F">F</option>
                        <option value="G">G</option>
                        <option value="H">H</option>
                        <option value="I">I</option>
                        <option value="J">J</option>
                        <option value="K">K</option>
                        <option value="L">L</option>
                        <option value="M">M</option>
                        <option value="N">N</option>
                        <option value="O">O</option>
                        <option value="P">P</option>
                        <option value="Q">Q</option>
                        <option value="R">R</option>
                        <option value="S">S</option>
                        <option value="T">T</option>
                        <option value="U">U</option>
                        <option value="V">V</option>
                        <option value="W">W</option>
                        <option value="X">X</option>
                        <option value="Y">Y</option>
                        <option value="Z">Z</option>
                      </select>
                    </div>
                  </div>

                  <button
                    onClick={() =>
                      handleAddPerformers(bulkPerformersCount, bulkLabel)
                    }
                    className="mt-4 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                  >
                    Add Performers
                  </button>
                </div>

                {/* Performer List */}
                <div className="mt-6">
                  <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm overflow-scroll h-1/5">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                          Performer #
                        </th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                          Label
                        </th>
                        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                          Role
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 overflow-scroll h-1/5">
                      {currentPerformers.map((performer, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            {performer.symbol}
                            {performer.number}
                          </td>
                          <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                            Band Member
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-between px-4 py-2">
                    <button
                      onClick={handlePreviousPage}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50 flex-1"
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span className="text-sm text-gray-700 m-auto text-center  flex-1">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={handleNextPage}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50  flex-1"
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {/* <button
                      type="submit"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:mt-0 sm:w-auto"
                    >
                      Save
                    </button> */}
                    <button
                      onClick={onClose}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformerModal;
