import React from "react";
import { useEffect, useState } from "react";
import LoadingSpinner from "../components/loadingSpinner";
import MarketingNavbar from "../components/marketingNav";
import { getInviteDetails } from "../services/userService.tsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  // signInWithGoogle,
} from "../services/firebase";
import { db } from "../services/firebase";
import { toast } from "react-hot-toast";
import { findUserByEmail, setInviteToUsed } from "../services/userService.tsx";
const InvitationPage = () => {
  const [inviteDetails, setInviteDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [user, loading, error] = useAuthState(auth);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  useEffect(() => {
    if (!token) return setIsLoading(false);
    const fetchInvite = async () => {
      try {
        const inviteDetails = await getInviteDetails(token);
        setInviteDetails(inviteDetails);
        setEmail(inviteDetails.email);
      } catch (error) {
        console.error("Error fetching invite:", error);
        toast.error("Invalid or expired invitation link.");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) fetchInvite();
  }, [token]);

  const register = async () => {
    if (!firstName) toast.error("Please Enter Your Name");
    if (!email) toast.error("Please Enter Your Email");
    if (!password) toast.error("Please Enter a Password");
    setIsLoading(true);
    try {
      await registerWithEmailAndPassword(firstName, lastName, email, password);
      const existingUser = await findUserByEmail(email);
      setInviteToUsed(token, existingUser.uid);
    } catch (error) {
      console.error("Error during registration or invite processing:", error);
      toast.error("Failed to create account or process invite.");
    }
    setIsLoading(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // if (codeExists) return checkCodeValid();
    if (user) navigate("/my-designs");
  }, [user, loading]);

  if (isLoading)
    return (
      <>
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );

  if (!inviteDetails)
    return (
      <>
        <MarketingNavbar />
        <p>Invalid or expired invitation.</p>
      </>
    );

  return (
    <div>
      <MarketingNavbar />

      <div class="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-20">
        <h1 class="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl">
          You've Been Invited to DrillFlo!
        </h1>
        <p class="mt-4 text-base/7 text-slate-600">
          Setup your DrillFlo account to continue
        </p>
      </div>
      <div class="mt-8 space-y-6 md:w-3/5 m-auto w-7/8">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="first-name" class="sr-only">
              First Name
            </label>
            <input
              id="first-name"
              name="name"
              type="name"
              autocomplete="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="First Name"
            />
          </div>
          <div>
            <label for="last-name" class="sr-only">
              Last name
            </label>
            <input
              id="last-name"
              name="lastname"
              type="lastName"
              autocomplete="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Last Name"
            />
          </div>
          <div>
            <label for="email-address" class="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              disabled
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
            />
          </div>
          <div>
            <label for="password" class="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center"></div>
        </div>

        <div>
          <button
            type="submit"
            onClick={register}
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg
                class="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Create My Account
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default InvitationPage;
