/**
 * @file Page.ts
 * @module Page
 * @description Represents a single page of drill, containing the positioning
 * of all performers.
 * @author Marcus Dubreuil
 */
export default class Page {
    drill;
    _performerPositions;
    _customMessage = "";
    constructor(drill, lastPage) {
        this.drill = drill;
        this._performerPositions = new Map();
        if (lastPage) {
            // Copy performer positions from the last page
            this._performerPositions = new Map(lastPage.performerPositions);
        }
    }
    /**
     * Get the current performer positions, mapping each performer to their position in the drill.
     */
    get performerPositions() {
        return this._performerPositions;
    }
    /**
     * Get the custom message for the page.
     */
    get customMessage() {
        return this._customMessage;
    }
    /**
     * Set a custom message for the page.
     * @param message The message to set.
     */
    set customMessage(message) {
        this._customMessage = message;
    }
    /**
     * When a new peformer is added, we need to assign them a default position.
     * This method will return the next available default position.
     *
     * For now, it just returns the next available vertical position at the center (heading down).
     *
     * @returns {StepPosition} The next available default position.
     */
    getNextAvailableDefaultPosition() {
        // Get all StepPositions where horizontalStepsFrom50 is 0,
        // and sort them by verticalStepsFromCenter
        const usedVerticalPositions = Array.from(this._performerPositions.values())
            .filter((position) => position.horizontalStepsFrom50 === 0)
            .map((position) => position.verticalStepsFromCenter)
            .sort((a, b) => a - b);
        // Find the next available vertical position
        let nextVerticalPosition = usedVerticalPositions.length == 0
            ? 0
            : usedVerticalPositions[usedVerticalPositions.length - 1] + 1;
        return {
            horizontalStepsFrom50: 0,
            verticalStepsFromCenter: nextVerticalPosition,
        };
    }
    /**
     * Get the position of a specific performer.
     * @param performer The performer whose position is to be retrieved.
     * @returns {StepPosition | undefined} The position of the performer, or undefined if not found.
     */
    getPerformerPosition(performer) {
        return this._performerPositions.get(performer);
    }
    /**
     * Set the position of a specific performer.
     * @param performer The performer whose position is to be set.
     * @param position [optional] The position to set for the performer. If not provided, a default position will be assigned.
     */
    setPerformerPosition(performer, position) {
        this._performerPositions.set(performer, position || this.getNextAvailableDefaultPosition());
        this.drawIfSelected();
    }
    drawIfSelected() {
        if (this.drill.selectedPage === this) {
            this.drill.draw();
        }
    }
    /**
     * Serialize the page data for storage or transmission.
     * Includes performer positions.
     */
    serialize() {
        return {
            performerPositions: Array.from(this._performerPositions.entries())
                // Sort performers by their ID for consistent serialization
                .sort(([performerA], [performerB]) => performerA.id.localeCompare(performerB.id))
                .map(([performer, position]) => ({
                performerId: performer.id,
                position,
            })),
            customMessage: this._customMessage,
        };
    }
}
