import { db, getUserPlanData } from "../services/firebase";
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  Timestamp,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { auth, logout } from "../services/firebase";
import DashboardHeader from "../components/dashboardHeader";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { onAuthStateChanged } from "firebase/auth";
import MarketingNavbar from "../components/marketingNav";
import LoadingSpinner from "../components/loadingSpinner";
import NoUploadsYet from "../components/elements/noUploadsYet";
import ProjectCard from "../components/elements/drillCard.tsx";
import StorageBanner from "../components/elements/storageBanner.tsx";
import UpgradeModal from "../components/elements/upgradeModal.tsx";
import { toast } from "react-hot-toast";

export default function MyDesigns() {
  const [drills, setDrills] = useState([]);
  const [sharedWithMeDrills, setSharedWithMeDrills] = useState([]);

  const [loading, setLoading] = useState(true);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [newDrillName, setNewDrillName] = useState("");
  const [userData, setUserData] = useState(null); // Store user data
  const [uploadingAvailable, setUploadingAvailable] = useState(false);
  const [isPlanInactive, setIsPlanInactive] = useState(null); // Store user data
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [storageLimit, setStorageLimit] = useState(5);
  const navigate = useNavigate();
  const [designTab, setDesignTab] = useState(1);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDrill, setSelectedDrill] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(true);
        fetchDrills(auth.currentUser.uid); // Pass the user ID
        fetchUserData();
        setLoading(false);
      } else {
        // alert("No user found, please log in");
        navigate("/login"); // Or redirect to login
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth, navigate]); // Include `navigate` in dependencies

  useEffect(() => {
    // Only check the upload status when both userData and drills are available
    if (userData && drills.length >= 0) {
      checkUploadStatus();
    }
  }, [userData, drills]);

  const fetchDrills = async (userId) => {
    if (!userId) return;
    setLoading(true);
    try {
      // Create a proper query to filter by user ID
      const designsQuery = query(
        collection(db, "designs"),
        where("createdBy", "==", userId)
      );

      const querySnapshot = await getDocs(designsQuery);
      const drillList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDrills(drillList);

      const designsRef = collection(db, "designs");
      console.log("ID", userId);

      const sharedQuery = query(
        designsRef,
        where("collaboratorIds", "array-contains", {
          uid: userId,
        })
      );

      const sharedQuerySnapshot = await getDocs(sharedQuery);
      const sharedDrillList = sharedQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("SHARED", sharedDrillList);
      setSharedWithMeDrills(sharedDrillList);
    } catch (error) {
      console.error("Error fetching drills:", error);
    }
    setLoading(false);
  };

  const fetchUserData = async () => {
    setLoading(true);
    // console.log(auth.currentUser.uid);
    try {
      // Query the "users" collection to find the document with the matching uid
      const q = query(
        collection(db, "users"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If we found a matching document, set the user data
        const userDoc = querySnapshot.docs[0]; // Assuming there's only one document per uid
        setUserData({ id: userDoc.id, ...userDoc.data() });
      } else {
        console.error("No user data found for the current UID");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
  };
  const handleDeleteDrill = async () => {
    if (!selectedDrill) return;

    // try {
    //   // Delete the drill from Firestore
    //   await deleteDoc(doc(db, "designs", selectedDrill.id));

    //   // Update the local state to reflect the deletion
    //   setDrills((prevDrills) =>
    //     prevDrills.filter((drill) => drill.id !== selectedDrill.id)
    //   );

    //   // Close the modal
    //   setShowDeleteModal(false);
    //   setSelectedDrill(null);
    // } catch (error) {
    //   console.error("Error deleting drill:", error);
    // }
  };
  const createNewDrill = async () => {
    // if (newDrillName.trim() === "") return;
    setLoading(true);
    const newDrill = {
      title: "Untitled Drill Design",
      createdAt: Timestamp.now(),
      createdBy: auth.currentUser.uid,
      // Add any other initial drill properties here
    };

    try {
      const docRef = await addDoc(collection(db, "designs"), newDrill);
      setNewDrillName("");
      fetchDrills(auth.currentUser.uid); // Pass the user ID
      // After successfully creating the document, navigate to its page
      navigate(`/designs/${docRef.id}`); // Navigate to the page
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    await delay(900);

    setLoading(false);
  };

  const checkUploadStatus = async () => {
    setLoading(true);
    setUploadingAvailable(false);

    try {
      const planData = await getUserPlanData(auth.currentUser.uid);
      console.log(planData);
      if (!planData || !userData) {
        console.error("Plan data or user data is missing");
        setLoading(false);
        return;
      }

      const { planKey, planActive } = planData;
      if (planKey === null && drills.length < 10) {
        setIsPlanInactive(false);
        setUploadingAvailable(true);
        setStorageLimit(10);

        return;
      }
      if (planActive === false) {
        setIsPlanInactive(true);
        setUploadingAvailable(false);
        return;
      }

      if (planActive === true) {
        if (
          (planKey === "standard_monthly" && drills.length < 50) ||
          planKey === "pro_monthly" ||
          planKey === "pro_annual" ||
          (planKey === "standard_annual" && drills.length < 50) ||
          (planKey === "free" && drills.length < 10)
        ) {
          setUploadingAvailable(true);
        }
        if (planKey === "standard_monthly") setStorageLimit(50);
        else if (planKey === "pro_monthly") setStorageLimit(100000000000);
        else if (planKey === "pro_annual") setStorageLimit(100000000000);
        else if (planKey === "standard_annual") setStorageLimit(50);
        else if (planKey === "free") setStorageLimit(10);
      }
    } catch (error) {
      console.error("Error checking upload status:", error);
    } finally {
      await delay(900);
      setLoading(false);
    }
  };
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  // console.log(oneWeekAgo);

  if (loading)
    return (
      <>
        <MarketingNavbar />
        <LoadingSpinner />
      </>
    );
  return (
    <div className="">
      <Helmet>
        <title>Drill Designs | DrillFlo</title>
      </Helmet>
      <MarketingNavbar />

      <header className="border-b border-gray-200 bg-gradient-to-r from-blue-500 to-purple-600 ">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <div className="flex flex-col items-start gap-4 md:flex-row md:items-center md:justify-between">
            <div>
              <h1 className="text-2xl font-extrabold text-white sm:text-4xl">
                Designs
              </h1>
            </div>

            <div className="flex items-center gap-4"></div>
          </div>
        </div>
      </header>

      <StorageBanner
        currentUsage={drills.length}
        storageLimit={storageLimit}
        onUpgradeClick={() => setShowUpgradeModal(true)}
      />

      <div>
        <div className="sm:hidden">
          <label htmlFor="Tab" className="sr-only">
            Tab
          </label>

          <select id="Tab" className="w-full rounded-md border-gray-200">
            <option>Settings</option>
            <option>Messages</option>
            <option>Archive</option>
            <option select>Notifications</option>
          </select>
        </div>

        <div className="hidden sm:block md:px-20">
          <nav className="flex gap-6" aria-label="Tabs">
            <a
              onClick={() => setDesignTab(1)}
              className={`shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700${
                designTab === 1 &&
                "bg-blue-300 p-2 text-sm font-medium text-blue-600 underline"
              }
            `}
            >
              My Designs
            </a>

            <a
              onClick={() => setDesignTab(2)}
              className={`shrink-0 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-700${
                designTab === 2 &&
                "bg-blue-300 p-2 text-sm font-medium text-blue-600 underline"
              }
            `}
            >
              Shared With Me
            </a>
          </nav>
        </div>
      </div>
      {designTab === 1 && (
        <>
          {isPlanInactive && (
            <span className="inline-flex items-center justify-center rounded-full bg-red-100  mx-24 px-2.5 py-0.5 text-red-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ms-1 me-1.5 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>

              <p className="whitespace-nowrap text-sm">
                Your Subscription is Inactive. Please visit{" "}
                <Link to="/my-account" className="underline">
                  My Account
                </Link>
              </p>
            </span>
          )}
          <h2 className="font-extrabold md:text-3xl pb-4 mt-8 bg-gradient-to-r from-blue-500 to-purple-500  bg-clip-text text-transparent px-24  ">
            My Designs
          </h2>
          {uploadingAvailable === true && drills.length == 0 && (
            <>
              <NoUploadsYet createNewDrill={createNewDrill} />
            </>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-8 ">
            {uploadingAvailable === false && (
              <div className="block rounded-lg p-4 shadow-sm text-center m-auto shadow-indigo-100 hover:bg-gray-200 transition-all ease-in-out bg-gradient-to-r from-blue-500 to-purple-600  text-white">
                {" "}
                <h3 className="font-bold text-lg">No More Uploads</h3>
                <span>Upgrade to unlock more</span>
              </div>
            )}
            {uploadingAvailable === true && drills.length > 0 && (
              <button
                onClick={createNewDrill}
                className="block rounded-lg p-4 shadow-sm text-center m-auto shadow-indigo-100 hover:bg-gray-200 transition-all ease-in-out hover:scale-110 hover:shadow-2xl text-white bg-gradient-to-r from-blue-500 to-purple-600 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
                {/* <h3>New Drill</h3> */}
              </button>
            )}{" "}
            {drills
              .sort((a, b) => {
                // Sort by updatedAt, most recent first
                return b.updated?.toDate() - a.updated?.toDate();
              })
              .map((drill) => (
                <>
                  <ProjectCard
                    title={drill.title}
                    link={drill.id}
                    // setSelectedDrill={setSelectedDrill(drill)}
                    // setShowDeleteModal={setShowDeleteModal}
                    lastUpdated={
                      drill.updated.toDate() > oneWeekAgo
                        ? drill.updated.toDate().toLocaleDateString(undefined, {
                            weekday: "long",
                            // month: "long",
                          })
                        : drill.updated.toDate().toLocaleDateString()
                    }
                    thumbnail={
                      "https://media.sweetwater.com/m/products/image/f01cbad910PNt0umUIZ7D4QsGUaGKLoT3R5pDix9.wm-lw.png?quality=82&width=750&ha=f01cbad910f6d506"
                    }
                    favorite
                    status=""
                  />
                </>
              ))}
          </div>{" "}
        </>
      )}
      {designTab === 2 && (
        <>
          {isPlanInactive && (
            <span className="inline-flex items-center justify-center rounded-full bg-red-100  mx-24 px-2.5 py-0.5 text-red-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="-ms-1 me-1.5 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>

              <p className="whitespace-nowrap text-sm">
                Your Subscription is Inactive. Please visit{" "}
                <Link to="/my-account" className="underline">
                  My Account
                </Link>
              </p>
            </span>
          )}
          <h2 className="font-extrabold md:text-3xl pb-4 mt-8 bg-gradient-to-r from-blue-500 to-purple-500  bg-clip-text text-transparent px-24  ">
            Shared with Me
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-8 ">
            {sharedWithMeDrills
              .sort((a, b) => {
                // Sort by updatedAt, most recent first
                return b.updated?.toDate() - a.updated?.toDate();
              })
              .map((drill) => (
                <>
                  <ProjectCard
                    title={drill.title}
                    link={drill.id}
                    lastUpdated={
                      drill.updated.toDate() > oneWeekAgo
                        ? drill.updated.toDate().toLocaleDateString(undefined, {
                            weekday: "long",
                            // month: "long",
                          })
                        : drill.updated.toDate().toLocaleDateString()
                    }
                    thumbnail={
                      "https://media.sweetwater.com/m/products/image/f01cbad910PNt0umUIZ7D4QsGUaGKLoT3R5pDix9.wm-lw.png?quality=82&width=750&ha=f01cbad910f6d506"
                    }
                    favorite
                    status=""
                  />
                </>
              ))}
          </div>
        </>
      )}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-lg font-bold">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete this design? This action cannot be
              undone.
            </p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteDrill}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        currentUsage={20}
        storageLimit={20}
      />
    </div>
  );
}
