import React, { useState } from "react";
import { Music } from "lucide-react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import AudioWaveform from "./audioWaveform.tsx";
import { audioBufferToWav } from "../services/audioBufferToWave.tsx";

interface AudioSettingsModalProps {
  setShowAudioTrackModal: (show: boolean) => void;
  showAudioTrackModal: boolean;
  audioUrl: string | null;
  slug: string;
  setDebugInfo: (info: string) => void;
  setAudioUrl: (url: string) => void;
}

const AudioSettingsModal = ({
  setShowAudioTrackModal,
  showAudioTrackModal,
  audioUrl,
  slug,
  setDebugInfo,
  setAudioUrl,
}: AudioSettingsModalProps) => {
  const [trimmedAudio, setTrimmedAudio] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [audioFile, setAudioFile] = useState<File | null>(null);

  const handleAudioUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        setIsUploading(true);
        setAudioFile(file);

        // Upload file to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `audio/${slug}/${file.name}`);
        await uploadBytes(storageRef, file);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        // Update Firestore document
        const docRef = doc(db, "designs", slug);
        await updateDoc(docRef, {
          audioFile: downloadURL,
          audioFileName: file.name,
          updated: new Date(),
        });

        setAudioUrl(downloadURL);
        console.log("Audio file uploaded and linked to the design");
      } catch (error) {
        console.error("Error uploading audio file:", error);
        // console.("Error uploading audio file. Please try again.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleTrimComplete = async (start: number, end: number) => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsUploading(true);

    try {
      if (!audioUrl) {
        console.error("Audio URL is undefined");
        return;
      }

      // Fetch the audio file
      const response = await fetch(audioUrl, { signal });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      // Decode the audio data
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Calculate the exact number of samples for the trimmed section
      const sampleRate = audioBuffer.sampleRate;
      const startSample = Math.floor(start * sampleRate);
      const endSample = Math.floor(end * sampleRate);
      const numberOfSamples = endSample - startSample;

      // Create a new buffer for the trimmed audio
      const trimmedBuffer = audioContext.createBuffer(
        audioBuffer.numberOfChannels,
        numberOfSamples,
        sampleRate
      );

      // Copy the desired portion of audio data
      for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        const trimmedData = trimmedBuffer.getChannelData(channel);

        // Copy the samples from the original buffer to the trimmed buffer
        for (let i = 0; i < numberOfSamples; i++) {
          trimmedData[i] = channelData[startSample + i];
        }
      }

      // Render the trimmed buffer to a WAV file
      const trimmedBlob = await new Promise<Blob>((resolve, reject) => {
        const offlineContext = new OfflineAudioContext(
          trimmedBuffer.numberOfChannels,
          trimmedBuffer.length,
          sampleRate
        );

        const source = offlineContext.createBufferSource();
        source.buffer = trimmedBuffer;
        source.connect(offlineContext.destination);

        // Start rendering
        source.start(0);

        offlineContext
          .startRendering()
          .then((renderedBuffer) => {
            // Convert to WAV with proper headers
            const numberOfChannels = renderedBuffer.numberOfChannels;
            const length = renderedBuffer.length;
            const wav = encodeWAV(renderedBuffer, numberOfChannels, sampleRate);
            const blob = new Blob([wav], { type: "audio/wav" });
            resolve(blob);
          })
          .catch(reject);
      });

      // Upload the trimmed audio
      const storage = getStorage();
      const fileName = `trimmed_${new Date().getTime()}.wav`;
      const storageRef = ref(storage, `audio/${slug}/${fileName}`);
      await uploadBytes(storageRef, trimmedBlob);

      const downloadURL = await getDownloadURL(storageRef);
      const docRef = doc(db, "designs", slug);
      await updateDoc(docRef, {
        audioFile: downloadURL,
        audioFileName: fileName,
        updated: new Date(),
      });

      setAudioUrl(downloadURL);
      setTrimmedAudio(downloadURL);
      console.log("Audio trimmed and uploaded successfully", downloadURL);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error trimming audio:", error);
      }
    } finally {
      setIsUploading(false);
    }
  };

  // WAV encoder function
  function encodeWAV(
    audioBuffer: AudioBuffer,
    numChannels: number,
    sampleRate: number
  ): DataView {
    const bitDepth = 16;
    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;

    // Calculate buffer sizes
    const dataSize = audioBuffer.length * blockAlign;
    const bufferSize = 44 + dataSize;
    const buffer = new ArrayBuffer(bufferSize);
    const view = new DataView(buffer);

    // Write WAV header
    writeString(view, 0, "RIFF"); // RIFF identifier
    view.setUint32(4, 36 + dataSize, true); // File size
    writeString(view, 8, "WAVE"); // WAVE identifier
    writeString(view, 12, "fmt "); // Format chunk identifier
    view.setUint32(16, 16, true); // Format chunk size
    view.setUint16(20, 1, true); // Audio format (PCM)
    view.setUint16(22, numChannels, true); // Number of channels
    view.setUint32(24, sampleRate, true); // Sample rate
    view.setUint32(28, sampleRate * blockAlign, true); // Byte rate
    view.setUint16(32, blockAlign, true); // Block align
    view.setUint16(34, bitDepth, true); // Bits per sample
    writeString(view, 36, "data"); // Data chunk identifier
    view.setUint32(40, dataSize, true); // Data chunk size

    // Write audio data
    const offset = 44;
    const channels = [];
    for (let i = 0; i < numChannels; i++) {
      channels.push(audioBuffer.getChannelData(i));
    }

    let position = 0;
    const length = audioBuffer.length;

    while (position < length) {
      for (let i = 0; i < numChannels; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][position]));
        const value = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
        view.setInt16(
          offset + position * blockAlign + i * bytesPerSample,
          value,
          true
        );
      }
      position++;
    }

    return view;
  }

  // Helper function to write strings to DataView
  function writeString(view: DataView, offset: number, string: string): void {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  <Music className="size-6 text-indigo-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Audio Settings
                  </h3>
                  <div className="mt-4 space-y-4">
                    <div className="flex flex-col gap-2">
                      <label className="text-sm font-medium text-gray-700">
                        Upload Audio File
                      </label>
                      <input
                        type="file"
                        accept="audio/*"
                        onChange={handleAudioUpload}
                        disabled={isUploading}
                        className="file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100 disabled:opacity-50"
                      />
                    </div>

                    {audioUrl && (
                      <div className="space-y-4">
                        <div className="rounded-lg border border-gray-200 p-4">
                          <h4 className="text-sm font-medium text-gray-700 mb-2">
                            Edit Audio
                          </h4>
                          <AudioWaveform
                            audioUrl={audioUrl}
                            onTrimComplete={handleTrimComplete}
                          />
                        </div>

                        {trimmedAudio && (
                          <div className="rounded-lg border border-gray-200 p-4">
                            <h4 className="text-sm font-medium text-gray-700 mb-2">
                              Trimmed Audio Preview
                            </h4>
                            <p>{trimmedAudio}</p>
                            <audio
                              controls
                              src={trimmedAudio}
                              className="w-full"
                            >
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        )}
                      </div>
                    )}

                    {isUploading && (
                      <div className="text-sm text-gray-600">
                        Processing audio... Please wait.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                onClick={() => setShowAudioTrackModal(!showAudioTrackModal)}
                disabled={isUploading}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed sm:ml-3 sm:w-auto"
              >
                Save Changes
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed sm:mt-0 sm:w-auto"
                onClick={() => setShowAudioTrackModal(!showAudioTrackModal)}
                disabled={isUploading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioSettingsModal;
