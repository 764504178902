import React, { useEffect, useRef, useState } from "react";
import {
  Document,
  Page,
  Image,
  Text,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import { gridToCanvasCoordinates } from "./renderingFunctions";

// Define styles for PDF components
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  image: {
    width: "95%",
    height: "65%",
    margin: "auto",
  },
  pageNumber: {
    position: "absolute",
    bottom: 10,
    left: 10,
    fontSize: 12,
  },
  headerData: {
    position: "absolute",
    top: 10,
    left: 10,
    fontSize: 10,
    fontWeight: 600,
  },
  headerTitle: {
    alignItems: "center",
    // margin: "auto",
    textAlign: "center",
    fontSize: 18,
  },
});
const fieldLength = 120;
const fieldWidth = 53.3;

const canvasWidth = 1100;

const yardLines = 120; // 100 yards plus 10 yards for each end zone

const stepsPerYard = 8 / 5; // 8 steps per 5 yards
const totalSteps = yardLines * stepsPerYard;
const canvasHeight = Math.round((fieldWidth / fieldLength) * canvasWidth);
const scale = canvasWidth / fieldLength;
const stepSize = canvasWidth / totalSteps;
// Function to draw on the canvas and convert to image
const drawCanvasToImage = (
  fieldColor,
  performers,
  width,
  height,
  performerLabelLocation
) => {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  console.log("Prformer", performers);
  // Clear the canvas
  ctx.clearRect(0, 0, width, height);

  // Draw field background
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvasWidth, canvasHeight);

  // Draw the grid under the yard lines
  ctx.strokeStyle = fieldColor;
  // ctx.lineWidth = 1;
  for (let i = 0; i <= totalSteps; i++) {
    const x = i * stepSize;
    ctx.beginPath();
    ctx.moveTo(x + 0.5, 0);
    ctx.lineTo(x + 0.5, canvasHeight);
    ctx.lineWidth = i % 4 === 0 ? 1.25 : 0.5;
    ctx.stroke();
  }

  for (let i = 0; i <= canvasHeight / stepSize; i++) {
    const y = i * stepSize;
    ctx.beginPath();
    ctx.moveTo(0, y + 0.5);
    ctx.lineTo(canvasWidth, y + 0.5);
    ctx.lineWidth = i % 4 === 0 ? 1.25 : 0.5;
    ctx.stroke();
  }

  // Draw white border around the field
  ctx.strokeStyle = "#333333";
  ctx.lineWidth = 4;
  ctx.strokeRect(0, 0, canvasWidth, canvasHeight);

  // Draw yard lines
  ctx.strokeStyle = "#333333";
  ctx.lineWidth = 2;
  for (let i = 0; i <= 120; i += 5) {
    const x = (i / fieldLength) * canvasWidth;
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, canvasHeight);
    ctx.stroke();

    // Add yard numbers
    if (i % 10 === 0 && i !== 0 && i !== 120) {
      ctx.fillStyle = "#808080";
      ctx.font = "28px Arial";
      ctx.textAlign = "center";
      const yardNumber = i <= 60 ? i - 10 : 110 - i;
      ctx.fillText(yardNumber.toString(), x, canvasHeight - canvasHeight / 7);
    }
    if (i % 10 === 0 && i !== 0 && i !== 120) {
      ctx.fillStyle = "#808080";
      ctx.font = "28px Arial";
      ctx.textAlign = "center";
      const yardNumber = i <= 60 ? i - 10 : 110 - i;
      ctx.fillText(yardNumber.toString(), x, canvasHeight / 7);
    }
  }
  // Draw performers
  performers.forEach((performer) => {
    const { x: canvasX, y: canvasY } = gridToCanvasCoordinates(
      performer.x,
      performer.y,
      canvasWidth,
      canvasHeight
    );
    ctx.fillStyle = "black"; // Example color for performers
    ctx.beginPath();
    const size = 5;

    ctx.moveTo(canvasX - size, canvasY - size);
    ctx.lineTo(canvasX + size, canvasY + size);
    ctx.moveTo(canvasX - size, canvasY + size);
    ctx.lineTo(canvasX + size, canvasY - size);
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.stroke();
    console.log("LABEL LOC", performerLabelLocation);
    if (performer.label) {
      ctx.font = "12px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      if (performer.label) {
        ctx.font = "12px Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        if (performerLabelLocation === "bottomRight") {
          ctx.fillText(performer.label, canvasX + 10, canvasY + 15);
        }
        if (performerLabelLocation === "bottomLeft") {
          ctx.fillText(performer.label, performer.x - 10, performer.y + 15);
        }
        if (performerLabelLocation === "topLeft") {
          ctx.fillText(performer.label, performer.x - 10, performer.y - 6);
        }
        if (performerLabelLocation === "topRight") {
          ctx.fillText(performer.label, performer.x + 10, performer.y - 6);
        }
        if (performerLabelLocation === "top") {
          ctx.fillText(performer.label, performer.x, performer.y - 6);
        }
        if (performerLabelLocation === "bottom") {
          ctx.fillText(performer.label, performer.x, performer.y + 15);
        }
      }
    }
  });

  return canvas.toDataURL("image/png");
};

// DrillPDF component
const DrillPDF = ({
  pages,
  fieldColor,
  width,
  height,
  title,
  performerLabelLocation,
}) => {
  const [images, setImages] = useState(Array(pages?.length).fill(null));

  useEffect(() => {
    const newImages = pages?.map((page) =>
      drawCanvasToImage(
        fieldColor,
        page.performers,
        width,
        height,
        performerLabelLocation
      )
    );
    setImages(newImages);
  }, [pages, fieldColor]);

  return (
    <Document title={title + " Drill Design"} creator="DrillFlo">
      {pages &&
        pages.map((page, index) => (
          <Page
            key={index}
            size="LETTER"
            orientation="landscape"
            style={styles.page}
          >
            <Text style={styles.headerData}>Created in DrillFlo</Text>
            <Text style={styles.headerTitle}>{title}</Text>
            {images[index] && (
              <Image src={images[index]} style={styles.image} />
            )}
            <Text style={styles.pageNumber}>
              Set #{index + 1} of {pages.length} - Counts: {pages[index].counts}{" "}
              - Measures:__
            </Text>
          </Page>
        ))}
    </Document>
  );
};

// PDFExportButton component
const PDFExportButton = ({ designData, width, height }) => {
  const { pages, fieldColor, title, performerLabelLocation } = designData;
  return (
    <div>
      <PDFDownloadLink
        document={
          <DrillPDF
            pages={pages}
            performerLabelLocation={performerLabelLocation}
            fieldColor={fieldColor}
            width={width}
            height={height}
            title={title}
          />
        }
        fileName={`${title || "drill"}.pdf`}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        style={{
          textDecoration: "none",
        }}
      >
        {({ blob, url, loading, error }) => {
          if (error) {
            console.error("Error generating PDF:", error);
            return "Error generating PDF";
          }
          if (loading) {
            console.log("PDF is generating...");
            return "Generating PDF...";
          }
          return "Download PDF";
        }}
      </PDFDownloadLink>
    </div>
  );
};

export default PDFExportButton;
