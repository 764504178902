import React, { useState, useEffect, useRef } from "react";
import { db } from "../services/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { CheckCircle2, MessageSquare, Send } from "lucide-react";
import { toast } from "react-hot-toast";

const Comments = ({
  designId,
  pageNumber,
  currentUser,
  setCommentCounts,
  commentCounts,
  collaborators,
}) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [mentionSearch, setMentionSearch] = useState("");
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const textareaRef = useRef(null);
  const [text, setText] = useState("");
  const [mentions, setMentions] = useState([]);

  const filteredUsers = collaborators.filter((user) =>
    // user.name.toLowerCase().includes(mentionSearch.toLowerCase()) ||
    user.email.toLowerCase().includes(mentionSearch.toLowerCase())
  );

  const handleInput = (e) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;
    const textBeforeCursor = newValue.slice(0, cursorPos);
    const matches = textBeforeCursor.match(/@(\w*)$/);

    if (matches) {
      setMentionSearch(matches[1]);
      setShowSuggestions(true);
      setSuggestionIndex(0);

      // Calculate position for suggestions popup
      const textArea = textareaRef.current;
      if (textArea) {
        const { offsetLeft, offsetTop } = textArea;
        setCursorPosition({
          left: offsetLeft + 10,
          top: offsetTop + 30,
        });
      }
    } else {
      setShowSuggestions(false);
    }

    // Extract mentions from text
    const mentions =
      newValue.match(/@\[(.*?)\]/g)?.map((mention) => {
        const user = collaborators.find((u) => `@[${u.name}]` === mention);
        return user?.id || "";
      }) || [];

    handleTextChange(newValue, mentions);
  };

  const insertMention = (user) => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const cursorPos = textarea.selectionStart;
    const textBeforeCursor = text.slice(0, cursorPos);
    const textAfterCursor = text.slice(cursorPos);
    const mentionText = `@${user.email}`;

    // Replace the @mention being typed with the full mention
    const newText =
      textBeforeCursor.replace(/@\w*$/, mentionText) + textAfterCursor;

    handleTextChange(newText, [...(text.match(/@\[(.*?)\]/g) || []), user.id]);
    setShowSuggestions(false);
  };

  useEffect(() => {
    const commentsRef = collection(db, "comments");
    const q = query(
      commentsRef,
      where("designId", "==", designId),
      where("pageNumber", "==", pageNumber),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setComments(fetchedComments.reverse());
    });

    return () => unsubscribe();
  }, [designId, pageNumber]);

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    if (!text.trim()) return;

    try {
      await addDoc(collection(db, "comments"), {
        designId,
        pageNumber,
        user: currentUser.email,
        userId: currentUser.uid,
        content: text,
        timestamp: new Date(),
        isCompleted: false,
        replies: [],
      });
      setNewComment("");
      setCommentCounts((prevCounts) => ({
        ...prevCounts,
        [pageNumber]: (prevCounts[pageNumber] || 0) + 1,
      }));
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };
  const handleTextChange = (newText: string, newMentions: string[]) => {
    setText(newText);
    setMentions(newMentions);
  };
  const handleReply = async (commentId) => {
    if (!replyContent.trim()) return;

    try {
      const commentRef = doc(db, "comments", commentId);
      const commentToUpdate = comments.find(
        (comment) => comment.id === commentId
      );

      const currentReplies = Array.isArray(commentToUpdate.replies)
        ? commentToUpdate.replies
        : []; // Default to an empty array if replies is missing or invalid

      await updateDoc(commentRef, {
        replies: [
          ...currentReplies,
          {
            user: currentUser.email,
            userId: currentUser.uid,
            content: replyContent,
            timestamp: new Date(),
          },
        ],
      });

      setReplyContent("");
      setReplyingTo(null);
    } catch (error) {
      console.error("Error adding reply: ", error);
    }
  };

  const toggleComplete = async (id) => {
    try {
      const commentRef = doc(db, "comments", id);
      const commentToUpdate = comments.find((comment) => comment.id === id);

      await updateDoc(commentRef, {
        isCompleted: !commentToUpdate.isCompleted,
      });
    } catch (error) {
      console.error("Error updating completion status: ", error);
    }
  };

  return (
    <div className="z-40 w-2/6 mx-8 h-screen overflow-scroll max-h-full transition-transform">
      <h3 className="text-lg font-semibold mb-2">Comments</h3>
      <div className="flex flex-col h-full min-h-full">
        <div className="comments-list h-3/6 overflow-scroll max-h-full">
          {comments.map((comment) => (
            <div
              key={comment.id}
              className={`border border-gray-200 ${
                comment.replies && comment.replies?.length > 0 ? "mb-4" : ""
              }`}
            >
              <div
                className={`rounded-lg bg-white p-4 shadow-sm ${
                  comment.isCompleted
                    ? "bg-blue-50 text-white  overflow-clip"
                    : ""
                }`}
              >
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium text-xs text-gray-900">
                        {comment.user}
                      </span>
                      <span className=" text-xs text-gray-500">
                        {new Date(
                          comment.timestamp?.toDate()
                        ).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                    <p
                      className={`mt-1 text-gray-700 text-left ${
                        comment.isCompleted ? "text-gray-500" : ""
                      }`}
                    >
                      {comment.content}
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => toggleComplete(comment.id)}
                      className={`rounded-full p-1 transition-colors ${
                        comment.isCompleted
                          ? "text-green-600 hover:text-green-700"
                          : "text-gray-400 hover:text-gray-600"
                      }`}
                    >
                      <CheckCircle2 size={20} />
                    </button>
                  </div>
                </div>

                <div className="mt-3 flex items-center gap-4">
                  <button
                    onClick={() =>
                      setReplyingTo(
                        replyingTo === comment.id ? null : comment.id
                      )
                    }
                    className="flex items-center gap-1 text-sm text-gray-500 hover:text-gray-700"
                  >
                    <MessageSquare size={16} />
                    Reply
                  </button>
                </div>

                {replyingTo === comment.id && (
                  <div className="mt-3">
                    <textarea
                      value={replyContent}
                      onChange={(e) => setReplyContent(e.target.value)}
                      className="w-full resize-none rounded-lg border border-gray-200 bg-white p-3 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                      rows={1}
                      placeholder="Write a reply..."
                    />
                    <button
                      onClick={() => handleReply(comment.id)}
                      className="mt-2 inline-flex items-center justify-center rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 disabled:opacity-50"
                      disabled={!replyContent.trim()}
                    >
                      Reply
                    </button>
                  </div>
                )}

                {comment.replies && comment.replies.length > 0 && (
                  <div className="mt-3 space-y-3">
                    {comment.replies.map((reply, index) => (
                      <div
                        key={index}
                        className="ml-6 rounded-lg bg-gray-100 p-3 text-sm"
                      >
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-xs text-gray-900">
                            {reply.user}
                          </span>
                          <span className="text-xs text-gray-500">
                            {new Date(
                              reply.timestamp?.toDate()
                            ).toLocaleDateString("en-US", {
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </span>
                        </div>
                        <p className="mt-1 text-gray-700">{reply.content}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {comments.length == 0 && (
            <div>
              <span className="font-normal text-sm text-gray-600">
                {" "}
                No comments yet
              </span>
              <p className=" font-light pt-4 text-sm text-gray-600">
                Have a thought? Add a comment here to start a conversation
              </p>
            </div>
          )}
        </div>
        <div className=" min-w-full  mx-auto space-y-6">
          <div className="rounded-lg bg-white shadow-sm w-full min-w-full p-2 m-2 m-auto gap-4 flex flex-col">
            <h2 className="text-md font-semibold mb-4">New Comment</h2>
            <form
              onSubmit={handleSubmitComment}
              className="w-full flex flex-row w-full gap-2"
            >
              <div className="relative">
                <textarea
                  ref={textareaRef}
                  value={text}
                  onChange={handleInput}
                  placeholder={"Add comment"}
                  className="flex-1 resize-none rounded-lg border border-gray-200 bg-white p-3 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
                  rows={1}
                />

                {showSuggestions && filteredUsers.length > 0 && (
                  <div
                    className="absolute z-10 mt-1 w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    style={{
                      top: cursorPosition.top,
                      left: cursorPosition.left,
                    }}
                  >
                    <ul className="max-h-60 overflow-auto py-1 text-base">
                      {filteredUsers.map((user, index) => (
                        <li
                          key={user.id}
                          className={`cursor-pointer px-3 py-2 hover:bg-gray-100 ${
                            index === suggestionIndex ? "bg-gray-100" : ""
                          }`}
                          onClick={() => insertMention(user)}
                        >
                          <div className="flex items-center">
                            <span className="font-medium">{user.name}</span>
                            <span className="ml-2 text-sm text-gray-500">
                              {user.email}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <button
                type="submit"
                disabled={!text.trim()}
                className="inline-flex items-center justify-center rounded-lg bg-blue-600 p-2 text-white hover:bg-blue-700 disabled:opacity-50"
              >
                <Send className=" w-12" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
