import React from "react";
import { Command } from "lucide-react";

type KeyProps = {
  children: React.ReactNode;
};

const Key: React.FC<KeyProps> = ({ children }) => (
  <kbd className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg shadow-sm">
    {children}
  </kbd>
);

type ShortcutProps = {
  keys: string[];
  description: string;
};

export const KeyboardShortcut: React.FC<ShortcutProps> = ({
  keys,
  description,
}) => (
  <div className="flex items-center justify-between py-2">
    <span className="text-sm text-gray-600">{description}</span>
    <div className="flex items-center gap-1">
      {keys.map((key, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="text-gray-400">+</span>}
          <Key>{key}</Key>
        </React.Fragment>
      ))}
    </div>
  </div>
);

type ShortcutsListProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ShortcutsList: React.FC<ShortcutsListProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[10000]">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-md p-6 m-4 ">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-2">
            <Command className="w-5 h-5 text-indigo-600" />
            <h2 className="text-xl font-semibold text-gray-900">
              Keyboard Shortcuts
            </h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-3">
              Mode Changing
            </h3>
            <div className="space-y-2">
              <KeyboardShortcut keys={["1"]} description="Pan mode" />
              <KeyboardShortcut keys={["2"]} description="Select Performers" />
              <KeyboardShortcut keys={["3"]} description="Draw Shape" />
              <KeyboardShortcut keys={["4"]} description="Comments" />
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-3">
              Performance
            </h3>
            <div className="space-y-2">
              <KeyboardShortcut keys={["Space"]} description="Play/Pause" />
              <KeyboardShortcut keys={["]"]} description="Next Page" />
              <KeyboardShortcut keys={["["]} description="Previous Page" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
