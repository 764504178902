import { link } from "fs";
import React, { useEffect, useState } from "react";

export function ChangelogHighlights() {
  const parser = new window.RSSParser();
  const [rssfeed, setRssFeed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getChangelog = async () => {
    let feed = await parser.parseURL(
      "https://drillflo.com/.netlify/functions/rss-feed"
    );

    setRssFeed(feed.items);
    console.log(feed.items);
  };

  useEffect(() => {
    setIsLoading(true);
    getChangelog();
    setIsLoading(false);
  }, []);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };
  return (
    <div className="space-y-4">
      {rssfeed.slice(0, 2).map((change) => (
        <div
          key={formatDate(change.isoDate)}
          className="border-b border-gray-200 pb-4 last:border-0"
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-medium text-sm">
              {formatDate(change.isoDate)}
            </h3>
          </div>
          <a
            className="font-bold text-left line-clamp-1 hover:underline"
            href={change.link}
          >
            {change.title}
          </a>
          <ul className="space-y-1">
            <li
              key={change}
              className="text-sm text-gray-600 overflow-ellipsis w-full line-clamp-2 text-left"
            >
              • {change.contentSnippet}
            </li>
          </ul>
        </div>
      ))}
      <a href="/changelog" className="text-sm font-normal mt-2">
        See Full Changelog
      </a>
    </div>
  );
}
