import MarketingNavbar from "../components/marketingNav";

export default function GuideBookPage() {
  return (
    <>
      <div className="h-screen">
        <MarketingNavbar />

        <iframe
          class="clickup-embed clickup-dynamic-height"
          src="https://doc.clickup.com/9011662620/d/h/8cj63rw-1871/3313333aca870ef"
          onwheel=""
          width="100%"
          height="100%"
          style={{
            background: "transparent",
            border: "1px solid #ccc",
          }}
        />
        <script
          async
          src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
        ></script>
      </div>
    </>
  );
}
