import React, { useState } from "react";
import { useEffect } from "react";
import MarketingNavbar from "../components/marketingNav";
import { marked } from "marked";
import { Helmet } from "react-helmet";

export default function ChangeLogPage() {
  const parser = new window.RSSParser();
  const [rssfeed, setRssFeed] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getChangelog = async () => {
    let feed = await parser.parseURL(
      "https://drillflo.com/.netlify/functions/rss-feed"
    );

    setRssFeed(feed.items);
    console.log(feed.items);
  };

  useEffect(() => {
    setIsLoading(true);
    getChangelog();
    setIsLoading(false);
  }, []);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>DrillFlo ✨ Changelog</title>
      </Helmet>
      <MarketingNavbar />
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-20">
          <h1 class="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl">
            Changelog
          </h1>
          <p class="mt-4 text-base/7 text-slate-600">
            Stay up to date with all of the latest additions and improvements
            we've made to DrillFlo. Vote and request new features with our
            public roadmap!
          </p>

          <a
            href="https://drillflo.canny.io/feature-requests"
            class=" mt-4 inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-blue-600 text-white hover:bg-blue-400 flex-none"
          >
            <span>Request a new Feature</span>
          </a>
        </div>
      </div>
      <div className="relative mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        {rssfeed.map((item, index) => (
          <section key={index} className="md:flex">
            <h2
              id={item.pubDate}
              class="pl-7 text-sm/6 text-slate-500 md:w-1/4 md:pr-12 md:pl-0 md:text-right"
            >
              <a href="#">{formatDate(item.isoDate)}</a>
            </h2>
            <div class="relative pt-2 pl-7 md:w-3/4 md:pt-0 md:pl-12 pb-16">
              <div className="absolute bottom-0 left-0 w-px bg-slate-200 -top-3 md:top-2.5"></div>
              <div className="absolute -top-[1.0625rem] -left-1 h-[0.5625rem] w-[0.5625rem] rounded-full border-2 border-slate-300 bg-white md:top-[0.4375rem]"></div>
              <div className="text-left prose-h3:mb-4 prose-h3:text-base/6 max-w-none prose prose-sm prose-slate prose-a:font-semibold prose-a:text-sky-500 prose-a:no-underline prose-a:hover:text-sky-600 prose-code:text-[13px]/[1.692] prose-pre:rounded-lg prose-pre:px-4 prose-pre:py-[0.875rem]">
                <span>
                  {item.categories.map((item, index) => (
                    <span className="inline-flex capitalize items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700">
                      {item}
                    </span>
                  ))}
                </span>
                <h3 className="font-bold">{item.title}</h3>

                <p className=" text-gray-600 mt-4 leading-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(item.content || ""),
                    }}
                  ></div>
                </p>
              </div>
            </div>
          </section>
        ))}
      </div>
    </>
  );
}
