import React from "react";
import { Printer, ChevronLeft, ChevronRight, X, Download } from "lucide-react";

export default function PrintPreview({
  exportCanvas,
  setShowExportDialog,
  exportEngine,
}) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <canvas
        id="exportCanvas"
        className=" shadow-lg rounded-md"
        ref={exportCanvas}
        style={{
          width: "calc(min(80vw, 80vh * (11 / 8.5)))",
          height: "calc(min(80vw, 80vh * (11 / 8.5)) * (8.5 / 11))",
          backgroundColor: "white",
        }}
      ></canvas>
      <div
        className="flex items-center gap-6 mt-6 bg-muted/30 p-4 rounded-lg backdrop-blur-sm w-full max-w-3xl mx-auto"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95vw",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <button
          className="flex flex-row"
          style={{
            padding: "10px 20px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => setShowExportDialog(false)}
        >
          {" "}
          <X className="w-4 h-4 mr-2 m-auto" />
          Cancel
        </button>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "gray",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            if (exportEngine) {
              exportEngine.previousPage();
            }
          }}
        >
          <ChevronLeft className="w-5 h-5 m-auto" />
        </button>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "gray",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            if (exportEngine) {
              exportEngine.nextPage();
            }
          }}
        >
          <ChevronRight className="w-5 h-5" />
        </button>
        <button
          className="flex flex-row "
          style={{
            padding: "10px 20px",
            backgroundColor: "green",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            if (exportEngine) {
              exportEngine.print();
            }
          }}
        >
          {" "}
          <Printer className="w-4 h-4 m-auto mr-2" />
          Print
        </button>
      </div>
    </div>
  );
}
